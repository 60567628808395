:root {
    --light: 300;
    --regular: 400;
    --medium: 500;
    --bold: 600;
    --extra-bold: 700;
    font-family: 'Poppins', serif;
}

body {
    line-height: 1.35 !important;
    font-family: 'Poppins', serif;
}

* {
    margin: 0;
    padding: 0;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

img {
    max-width: 100%;
    height: auto;
}

h1 {
    font-size: 22px;
}

h2 {
    font-size: 20px;
}

h3 {
    font-size: 18px;
}

h4 {
    font-size: 16px;
}

h5 {
    font-size: 14px;
}

h6 {
    font-size: 14px;
}

.patient-logo {
    object-fit: contain;
    max-width: 100%;
    max-height: 50px;
    width: auto;
    height: auto;
}

.admin-logo {
    object-fit: contain;
    max-width: 100%;
    max-height: 50px;
    width: auto;
    height: auto;
}

.goog-te-gadget a {
    display: none !important;
}

.skiptranslate.goog-te-gadget:first-child {
    display: block;
}
.goog-te-combo{
    margin-top:16px;
    border:1px solid #1a1a1a;
    padding:5px;
    border-radius: 10px;
}


.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.custom-shadow {
    background: #fff;
    box-shadow: 4px 8px 30px rgba(141, 141, 141, 0.1);
    border-radius: 14px;
}

.select-arrow {
    background: url(https://i.ibb.co/fCsFTVL/icn-dropdown-chevron.png) no-repeat;
    width: 15px;
    height: 8px;
    background-size: contain;
}

.multi-select button {
    border: 1px solid !important;
    width: 100% !important;
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
    font-family: "Source Sans Pro", sans-serif!important;
    font-weight: 400!important;
    color: rgb(17, 24, 39)!important;
    background-color: rgb(255, 255, 255)!important;
    border-bottom-color: rgb(55, 65, 81)!important;
    border-bottom-style: solid!important;
    border-bottom-width: 1px!important;
    border-left-color: rgb(55, 65, 81)!important;
    border-left-style: solid!important;
    border-left-width: 1px!important;
    border-right-color: rgb(55, 65, 81)!important;
    border-right-style: solid!important;
    border-right-width: 1px!important;
    border-top-color: rgb(55, 65, 81)!important;
    border-top-style: solid!important;
    border-top-width: 1px!important;
    box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px inset, rgb(209, 213, 219) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px!important;
    border-bottom-left-radius: 8px!important;
    border-bottom-right-radius: 8px!important;
    border-top-left-radius: 8px!important;
    border-top-right-radius: 8px!important;
    padding: 9.5px;
}

.multi-select__option--is-focused,
.multi-select__option--is-selected {
    background-color: transparent !important;
}

.gpay-card-info-container {
    border-radius: 60px !important;
}

.bgtmp {
    background: rgb(10, 143, 220);
    background: linear-gradient(0deg, rgba(10, 143, 220, 0) 0%, rgba(10, 143, 220, 0.1) 100%);
}

.multi-select__control {
    display: none !important;
}