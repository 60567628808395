.primary {}

.primary.text-blue,
.text-blue {
    color: #3c7ab5;
}

.border-blue {
    border-color: #3c7ab5;
}

.primary.bg-blue {
    background-color: #3c7ab5 !important;
}

.primary.text-green {
    color: #56be89;
}

.primary.bg-green {
    background-color: #56be89;
}

.secondary {}

.secondary.bg-salmon {
    background-color: #ef6f6c;
}

.secondary.bg-blue {
    background-color: #538fb2;
}

.secondary.bg-light {
    background-color: #edf4fd;
}

.secondary.bg-navy {
    background-color: #222b56;
}

.secondary.bg-green {
    background-color: #56be89;
}

.secondary.bg-grey {
    background-color: #808080;
}

.secondary.text-salmon {
    color: #ef6f6c;
}

.secondary.text-blue {
    color: #538fb2;
}

.secondary.text-light {
    color: #edf4fd;
}

.secondary.text-navy {
    color: #222b56;
}

.secondary.text-green {
    color: #56be89;
}

.secondary.text-grey {
    color: #808080;
}

.extended {}

.extended.bg-red {
    background-color: #a62316;
}

.extended.bg-green {
    background-color: #008852;
}

.extended.text-red, .text-red {
    color: #a62316;
}

.extended.text-green {
    color: #008852;
}

.gradient {}

.gradient.main {
    background: -webkit-linear-gradient(left, rgb(102, 158, 218) 0%, rgb(125, 190, 207) 100%);
    background: -o-linear-gradient(left, rgb(102, 158, 218) 0%, rgb(125, 190, 207) 100%);
    background: -ms-linear-gradient(left, rgb(102, 158, 218) 0%, rgb(125, 190, 207) 100%);
    background: -moz-linear-gradient(left, rgb(102, 158, 218) 0%, rgb(125, 190, 207) 100%);
    background: linear-gradient(to right, rgb(102, 158, 218) 0%, rgb(125, 190, 207) 100%);
}

.icon-white {
    filter: brightness(0) invert(1);
}

.icon-red {
    filter: invert(17%) sepia(81%) saturate(4706%) hue-rotate(355deg) brightness(78%) contrast(124%)
}

.icon-green {
    filter: invert(45%) sepia(10%) saturate(3298%) hue-rotate(70deg) brightness(92%) contrast(99%);
}

.icon-lightgreen {
    filter: invert(58%) sepia(79%) saturate(266%) hue-rotate(99deg) brightness(95%) contrast(92%);
}

.icon-blue {
    filter: invert(43%) sepia(49%) saturate(672%) hue-rotate(168deg) brightness(90%) contrast(85%);
}

.icon-grey {
    filter: invert(100%) sepia(1%) saturate(6078%) hue-rotate(188deg) brightness(124%) contrast(73%);
}

.icon-grey-dark {
    filter: invert(47%) sepia(3%) saturate(3%) hue-rotate(358deg) brightness(99%) contrast(87%);
}

.icon-grey-lightdark {
    filter: invert(47%) sepia(2%) saturate(2%) hue-rotate(358deg) brightness(99%) contrast(87%);
}

.arrow-down {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid #222b56;
}

.bg-yellow-50 {
    background-color: rgba(255, 251, 235, 1) !important
}

.text-yellow-700 {
    color: rgba(180, 83, 9, 1) !important;
}

.text-yellow-400 {
    color: rgba(251, 191, 36, 1) !important;
}

.bg-red-50 {
    background-color: rgba(254, 242, 242, 1) !important;
}

.text-red-400 {
    color: rgba(248, 113, 113, 1) !important;
}

.text-red-700 {
    color: rgba(185, 28, 28, 1) !important;
}

.bg-green {
    background-color: #56be89;
}