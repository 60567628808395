.navigation-item {
    transition: 0.5s;
}

.navigation-item:hover,
.navigation-item.active,
.nav-active .navigation-item {
    background: #F8FBFE;
    border-right-width: 5px;
    border-right-color: #3C7AB5;
}