.button {
    max-width: 160px;
    width: 160px;
    max-height: 48px;
    height: 48px;
    color: #fff;
    border-radius: 4px;
    font-family: Helvetica;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    line-height: 15px;
    padding: 16px;
    font-family: 'Source Sans Pro', sans-serif;
    position: relative;
}

button.small {
    max-width: 145px;
    width: 120px;
    padding-left: 0px;
    padding-right: 0px;
}

button.xs {
    max-width: 145px;
    width: 60px;
    padding-left: 0px;
    padding-right: 0px;
}

button.medium {
    max-width: 200px;
    width: 200px;
}

button.large {
    max-width: 328px;
    width: 328px;
}

button.xl {
    max-width: 100%;
}

button.w-full {
    max-width: 100%;
}

.button.primary {
    background: #3C7AB5;
    border: 1px solid #3C7AB5;
}

.button.primary.hover,
.button.primary:hover {
    background: #1c5a96;
}

.button.primary.active,
.button.primary:active {
    background: #0c4a85;
}

.button.secondary {
    background: #56be89;
    border: 1px solid #DDDDDD;
}

.button.gray {
    background: #F3F4F6;
    border: 1px solid #495057;
    color: #495057
}

button.gray:hover,
button.gray.hover {
    background: #898a8b;
    border: 1px solid #495057;
    color: #fff;
}

.button.secondary.hover,
.button.secondary:hover {
    background: #49a878;
}

.button.secondary.active,
.button.secondary:active {
    background: #49a878;
}

.button.tertiary {
    background: #fff;
    border: 1px solid #3c7ab5;
    color: #3c7ab5
}

.button.tertiary.hover,
.button.tertiary:hover {
    background: rgba(60, 122, 181, .2);
    color: #3c7ab5;
}

.button.tertiary.active,
.button.tertiary:active {
    background: #3c7ab5;
    color: #fff;
}

.button.danger {
    background: #F0544E;
    border: 1px solid #F0544E;
}

.button.danger.hover,
.button.danger:hover {
    background: #F0544E;
}

.button.danger.active,
.button.danger:active {
    background: #F0544E;
}

.button.text-link {
    background: #fff;
    border: none;
    color: #3c7ab5;
    box-shadow: none!important;
}

.button.text-link.hover,
.button.text-link:hover {
    background: rgba(60, 122, 181, .2);
    color: #3c7ab5;
}

.button.text-link.active,
.button.text-link:active {
    background: #3c7ab5;
    color: #fff;
}



.button.text-link-danger {
    background: #fff;
    border: none;
    color: #F0544E;
    box-shadow: none!important;
}

.button.text-link-danger.hover,
.button.text-link-danger:hover {
    background: rgba(181, 60, 60, 0.2);
    color: #F0544E;
}

.button.text-link.active,
.button.text-link:active {
    background: #3c7ab5;
    color: #fff;
}

.text-link {
    font-family: 'Source Sans Pro', sans-serif;
    color: #3c7ab5;
}

.text-link:hover,
.text-link.hover {
    text-decoration: underline;
}

.text-link:active,
.text-link.active {
    color: #1e5a92;
}


/* Toggle A */


/* Toggle B */

input:checked~.dot {
    transform: translateX(100%);
    background-color: #48bb78;
}

.apple-pay-button {
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: donate;
    /* Use any supported button type. */
}

.apple-pay-button-black {
    -apple-pay-button-style: black;
}

.apple-pay-button-white {
    -apple-pay-button-style: white;
}

.apple-pay-button-white-with-line {
    -apple-pay-button-style: white-outline;
}


/* CSS */

@supports (-webkit-appearance: -apple-pay-button) {
    .apple-pay-button {
        display: inline-block;
        -webkit-appearance: -apple-pay-button;
    }
    .apple-pay-button-black {
        -apple-pay-button-style: black;
    }
    .apple-pay-button-white {
        -apple-pay-button-style: white;
    }
    .apple-pay-button-white-with-line {
        -apple-pay-button-style: white-outline;
    }
}


/* CSS */

@supports (-webkit-appearance: -apple-pay-button) {
    .apple-pay-button-with-text {
        display: inline-block;
        -webkit-appearance: -apple-pay-button;
        -apple-pay-button-type: buy;
    }
    .apple-pay-button-with-text>* {
        display: none;
    }
    .apple-pay-button-black-with-text {
        -apple-pay-button-style: black;
    }
    .apple-pay-button-white-with-text {
        -apple-pay-button-style: white;
    }
    .apple-pay-button-white-with-line-with-text {
        -apple-pay-button-style: white-outline;
    }
}

@supports not (-webkit-appearance: -apple-pay-button) {
    .apple-pay-button-with-text {
        --apple-pay-scale: 1;
        /* (height / 32) */
        display: inline-flex;
        justify-content: center;
        font-size: 12px;
        border-radius: 5px;
        padding: 0px;
        box-sizing: border-box;
        min-width: 200px;
        min-height: 32px;
        max-height: 64px;
    }
    .apple-pay-button-black-with-text {
        background-color: black;
        color: white;
    }
    .apple-pay-button-white-with-text {
        background-color: white;
        color: black;
    }
    .apple-pay-button-white-with-line-with-text {
        background-color: white;
        color: black;
        border: .5px solid black;
    }
    .apple-pay-button-with-text.apple-pay-button-black-with-text>.logo {
        background-image: -webkit-named-image(apple-pay-logo-white);
        background-color: black;
    }
    .apple-pay-button-with-text.apple-pay-button-white-with-text>.logo {
        background-image: -webkit-named-image(apple-pay-logo-black);
        background-color: white;
    }
    .apple-pay-button-with-text.apple-pay-button-white-with-line-with-text>.logo {
        background-image: -webkit-named-image(apple-pay-logo-black);
        background-color: white;
    }
    .apple-pay-button-with-text>.text {
        font-family: -apple-system;
        font-size: calc(1em * var(--apple-pay-scale));
        font-weight: 300;
        align-self: center;
        margin-right: calc(2px * var(--apple-pay-scale));
    }
    .apple-pay-button-with-text>.logo {
        width: calc(35px * var(--scale));
        height: 100%;
        background-size: 100% 60%;
        background-repeat: no-repeat;
        background-position: 0 50%;
        margin-left: calc(2px * var(--apple-pay-scale));
        border: none;
    }
}