@import 'base.css';
@import 'vendors/index.css';
a.goog-logo-link {
    display: none;
}

.goog-logo-link {
    display: none !important;
}

.goog-te-gadget {
    color: transparent !important;
}

.goog-te-gadget .goog-te-combo {
    color: black !important;
}

.text-label:first-letter {
    text-transform: uppercase
}

.react-tel-input .flag-dropdown {
    border: none !important;
    background-color: transparent !important;
}

.react-tel-input .flag-dropdown.open {
    background: transparent !important;
}