.w-block {
    display: block;
}

.w-inline-block {
    max-width: 100%;
    display: inline-block;
}

.w-clearfix:before,
.w-clearfix:after {
    content: " ";
    grid-area: 1 / 1 / 2 / 2;
    display: table;
}

.w-clearfix:after {
    clear: both;
}

.w-hidden {
    display: none;
}

.w-button {
    color: #fff;
    line-height: inherit;
    cursor: pointer;
    background-color: #3898ec;
    border: 0;
    border-radius: 0;
    padding: 9px 15px;
    text-decoration: none;
    display: inline-block;
}

input.w-button {
    -webkit-appearance: button;
}

html[data-w-dynpage] [data-w-cloak] {
    color: rgba(0, 0, 0, 0) !important;
}

.w-webflow-badge,
.w-webflow-badge * {
    z-index: auto;
    visibility: visible;
    box-sizing: border-box;
    width: auto;
    height: auto;
    max-height: none;
    max-width: none;
    min-height: 0;
    min-width: 0;
    float: none;
    clear: none;
    box-shadow: none;
    opacity: 1;
    direction: ltr;
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    font-style: inherit;
    font-variant: inherit;
    text-align: inherit;
    letter-spacing: inherit;
    -webkit-text-decoration: inherit;
    text-decoration: inherit;
    text-indent: 0;
    text-transform: inherit;
    text-shadow: none;
    font-smoothing: auto;
    vertical-align: baseline;
    cursor: inherit;
    white-space: inherit;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    background: none;
    border: 0 rgba(0, 0, 0, 0);
    border-radius: 0;
    margin: 0;
    padding: 0;
    list-style-type: disc;
    transition: none;
    display: block;
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    overflow: visible;
    transform: none;
}

.w-webflow-badge {
    white-space: nowrap;
    cursor: pointer;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .1);
    visibility: visible !important;
    z-index: 2147483647 !important;
    color: #aaadb0 !important;
    opacity: 1 !important;
    width: auto !important;
    height: auto !important;
    background-color: #fff !important;
    border-radius: 3px !important;
    margin: 0 !important;
    padding: 6px 8px 6px 6px !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-decoration: none !important;
    display: inline-block !important;
    position: fixed !important;
    top: auto !important;
    bottom: 12px !important;
    left: auto !important;
    right: 12px !important;
    overflow: visible !important;
    transform: none !important;
}

.w-webflow-badge>img {
    visibility: visible !important;
    opacity: 1 !important;
    vertical-align: middle !important;
    display: inline-block !important;
}

.w-list-unstyled {
    padding-left: 0;
    list-style: none;
}

.w-embed:before,
.w-embed:after {
    content: " ";
    grid-area: 1 / 1 / 2 / 2;
    display: table;
}

.w-embed:after {
    clear: both;
}

.w-video {
    width: 100%;
    padding: 0;
    position: relative;
}

.w-video iframe,
.w-video object,
.w-video embed {
    width: 100%;
    height: 100%;
    border: none;
    position: absolute;
    top: 0;
    left: 0;
}

.w-form {
    margin: 0 0 15px;
}

.w-form-done {
    text-align: center;
    background-color: #ddd;
    padding: 20px;
    display: none;
}

.w-form-fail {
    background-color: #ffdede;
    margin-top: 10px;
    padding: 10px;
    display: none;
}

label {
    margin-bottom: 5px;
    font-weight: bold;
    display: block;
}

.w-input,
.w-select {
    width: 100%;
    height: 38px;
    color: #333;
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.42857;
    display: block;
}

.w-input:-moz-placeholder,
.w-select:-moz-placeholder {
    color: #999;
}

.w-input::-moz-placeholder,
.w-select::-moz-placeholder {
    color: #999;
    opacity: 1;
}

.w-input:-ms-input-placeholder,
.w-select:-ms-input-placeholder {
    color: #999;
}

.w-input::-webkit-input-placeholder,
.w-select::-webkit-input-placeholder {
    color: #999;
}

.w-input:focus,
.w-select:focus {
    border-color: #3898ec;
    outline: 0;
}

.w-input[disabled],
.w-select[disabled],
.w-input[readonly],
.w-select[readonly],
fieldset[disabled] .w-input,
fieldset[disabled] .w-select {
    cursor: not-allowed;
}

.w-input[disabled]:not(.w-input-disabled),
.w-select[disabled]:not(.w-input-disabled),
.w-input[readonly],
.w-select[readonly],
fieldset[disabled]:not(.w-input-disabled) .w-input,
fieldset[disabled]:not(.w-input-disabled) .w-select {
    background-color: #eee;
}

textarea.w-input,
textarea.w-select {
    height: auto;
}

.w-select {
    background-color: #f3f3f3;
}

.w-select[multiple] {
    height: auto;
}

.w-form-label {
    cursor: pointer;
    margin-bottom: 0;
    font-weight: normal;
    display: inline-block;
}

.w-radio {
    margin-bottom: 5px;
    padding-left: 20px;
    display: block;
}

.w-radio:before,
.w-radio:after {
    content: " ";
    grid-area: 1 / 1 / 2 / 2;
    display: table;
}

.w-radio:after {
    clear: both;
}

.w-radio-input {
    margin: 4px 0 0;
    margin-top: 1px \9;
    float: left;
    margin-top: 3px;
    margin-left: -20px;
    line-height: normal;
}

.w-file-upload {
    margin-bottom: 10px;
    display: block;
}

.w-file-upload-input {
    width: .1px;
    height: .1px;
    opacity: 0;
    z-index: -100;
    position: absolute;
    overflow: hidden;
}

.w-file-upload-default,
.w-file-upload-uploading,
.w-file-upload-success {
    color: #333;
    display: inline-block;
}

.w-file-upload-error {
    margin-top: 10px;
    display: block;
}

.w-file-upload-default.w-hidden,
.w-file-upload-uploading.w-hidden,
.w-file-upload-error.w-hidden,
.w-file-upload-success.w-hidden {
    display: none;
}

.w-file-upload-uploading-btn {
    cursor: pointer;
    background-color: #fafafa;
    border: 1px solid #ccc;
    margin: 0;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: normal;
    display: flex;
}

.w-file-upload-file {
    background-color: #fafafa;
    border: 1px solid #ccc;
    flex-grow: 1;
    justify-content: space-between;
    margin: 0;
    padding: 8px 9px 8px 11px;
    display: flex;
}

.w-file-upload-file-name {
    font-size: 14px;
    font-weight: normal;
    display: block;
}

.w-file-remove-link {
    width: auto;
    height: auto;
    cursor: pointer;
    margin-top: 3px;
    margin-left: 10px;
    padding: 3px;
    display: block;
}

.w-icon-file-upload-remove {
    margin: auto;
    font-size: 10px;
}

.w-file-upload-error-msg {
    color: #ea384c;
    padding: 2px 0;
    display: inline-block;
}

.w-file-upload-info {
    padding: 0 12px;
    line-height: 38px;
    display: inline-block;
}

.w-file-upload-label {
    cursor: pointer;
    background-color: #fafafa;
    border: 1px solid #ccc;
    margin: 0;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: normal;
    display: inline-block;
}

.w-icon-file-upload-icon,
.w-icon-file-upload-uploading {
    width: 20px;
    margin-right: 8px;
    display: inline-block;
}

.w-icon-file-upload-uploading {
    height: 20px;
}

.w-container {
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
}

.w-container:before,
.w-container:after {
    content: " ";
    grid-area: 1 / 1 / 2 / 2;
    display: table;
}

.w-container:after {
    clear: both;
}

.w-container .w-row {
    margin-left: -10px;
    margin-right: -10px;
}

.w-row:before,
.w-row:after {
    content: " ";
    grid-area: 1 / 1 / 2 / 2;
    display: table;
}

.w-row:after {
    clear: both;
}

.w-row .w-row {
    margin-left: 0;
    margin-right: 0;
}

.w-col {
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
}

.w-col .w-col {
    padding-left: 0;
    padding-right: 0;
}

.w-col-1 {
    width: 8.33333%;
}

.w-col-2 {
    width: 16.6667%;
}

.w-col-3 {
    width: 25%;
}

.w-col-4 {
    width: 33.3333%;
}

.w-col-5 {
    width: 41.6667%;
}

.w-col-6 {
    width: 50%;
}

.w-col-7 {
    width: 58.3333%;
}

.w-col-8 {
    width: 66.6667%;
}

.w-col-9 {
    width: 75%;
}

.w-col-10 {
    width: 83.3333%;
}

.w-col-11 {
    width: 91.6667%;
}

.w-col-12 {
    width: 100%;
}

.w-hidden-main {
    display: none !important;
}

@media screen and (max-width: 991px) {
    .w-container {
        max-width: 728px;
    }
    .w-hidden-main {
        display: inherit !important;
    }
    .w-hidden-medium {
        display: none !important;
    }
    .w-col-medium-1 {
        width: 8.33333%;
    }
    .w-col-medium-2 {
        width: 16.6667%;
    }
    .w-col-medium-3 {
        width: 25%;
    }
    .w-col-medium-4 {
        width: 33.3333%;
    }
    .w-col-medium-5 {
        width: 41.6667%;
    }
    .w-col-medium-6 {
        width: 50%;
    }
    .w-col-medium-7 {
        width: 58.3333%;
    }
    .w-col-medium-8 {
        width: 66.6667%;
    }
    .w-col-medium-9 {
        width: 75%;
    }
    .w-col-medium-10 {
        width: 83.3333%;
    }
    .w-col-medium-11 {
        width: 91.6667%;
    }
    .w-col-medium-12 {
        width: 100%;
    }
    .w-col-stack {
        width: 100%;
        left: auto;
        right: auto;
    }
}

@media screen and (max-width: 767px) {
    .w-hidden-main,
    .w-hidden-medium {
        display: inherit !important;
    }
    .w-hidden-small {
        display: none !important;
    }
    .w-row,
    .w-container .w-row {
        margin-left: 0;
        margin-right: 0;
    }
    .w-col {
        width: 100%;
        left: auto;
        right: auto;
    }
    .w-col-small-1 {
        width: 8.33333%;
    }
    .w-col-small-2 {
        width: 16.6667%;
    }
    .w-col-small-3 {
        width: 25%;
    }
    .w-col-small-4 {
        width: 33.3333%;
    }
    .w-col-small-5 {
        width: 41.6667%;
    }
    .w-col-small-6 {
        width: 50%;
    }
    .w-col-small-7 {
        width: 58.3333%;
    }
    .w-col-small-8 {
        width: 66.6667%;
    }
    .w-col-small-9 {
        width: 75%;
    }
    .w-col-small-10 {
        width: 83.3333%;
    }
    .w-col-small-11 {
        width: 91.6667%;
    }
    .w-col-small-12 {
        width: 100%;
    }
}

@media screen and (max-width: 479px) {
    .w-container {
        max-width: none;
    }
    .w-hidden-main,
    .w-hidden-medium,
    .w-hidden-small {
        display: inherit !important;
    }
    .w-hidden-tiny {
        display: none !important;
    }
    .w-col {
        width: 100%;
    }
    .w-col-tiny-1 {
        width: 8.33333%;
    }
    .w-col-tiny-2 {
        width: 16.6667%;
    }
    .w-col-tiny-3 {
        width: 25%;
    }
    .w-col-tiny-4 {
        width: 33.3333%;
    }
    .w-col-tiny-5 {
        width: 41.6667%;
    }
    .w-col-tiny-6 {
        width: 50%;
    }
    .w-col-tiny-7 {
        width: 58.3333%;
    }
    .w-col-tiny-8 {
        width: 66.6667%;
    }
    .w-col-tiny-9 {
        width: 75%;
    }
    .w-col-tiny-10 {
        width: 83.3333%;
    }
    .w-col-tiny-11 {
        width: 91.6667%;
    }
    .w-col-tiny-12 {
        width: 100%;
    }
}

.w-widget {
    position: relative;
}

.w-widget-map {
    width: 100%;
    height: 400px;
}

.w-widget-map label {
    width: auto;
    display: inline;
}

.w-widget-map img {
    max-width: inherit;
}

.w-widget-map .gm-style-iw {
    text-align: center;
}

.w-widget-map .gm-style-iw>button {
    display: none !important;
}

.w-widget-twitter {
    overflow: hidden;
}

.w-widget-twitter-count-shim {
    vertical-align: top;
    width: 28px;
    height: 20px;
    text-align: center;
    background: #fff;
    border: 1px solid #758696;
    border-radius: 3px;
    display: inline-block;
    position: relative;
}

.w-widget-twitter-count-shim * {
    pointer-events: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.w-widget-twitter-count-shim .w-widget-twitter-count-inner {
    text-align: center;
    color: #999;
    font-family: serif;
    font-size: 15px;
    line-height: 12px;
    position: relative;
}

.w-widget-twitter-count-shim .w-widget-twitter-count-clear {
    display: block;
    position: relative;
}

.w-widget-twitter-count-shim.w--large {
    width: 36px;
    height: 28px;
}

.w-widget-twitter-count-shim.w--large .w-widget-twitter-count-inner {
    font-size: 18px;
    line-height: 18px;
}

.w-widget-twitter-count-shim:not(.w--vertical) {
    margin-left: 5px;
    margin-right: 8px;
}

.w-widget-twitter-count-shim:not(.w--vertical).w--large {
    margin-left: 6px;
}

.w-widget-twitter-count-shim:not(.w--vertical):before,
.w-widget-twitter-count-shim:not(.w--vertical):after {
    content: " ";
    height: 0;
    width: 0;
    pointer-events: none;
    border: solid rgba(0, 0, 0, 0);
    position: absolute;
    top: 50%;
    left: 0;
}

.w-widget-twitter-count-shim:not(.w--vertical):before {
    border-width: 4px;
    border-color: rgba(117, 134, 150, 0) #5d6c7b rgba(117, 134, 150, 0) rgba(117, 134, 150, 0);
    margin-top: -4px;
    margin-left: -9px;
}

.w-widget-twitter-count-shim:not(.w--vertical).w--large:before {
    border-width: 5px;
    margin-top: -5px;
    margin-left: -10px;
}

.w-widget-twitter-count-shim:not(.w--vertical):after {
    border-width: 4px;
    border-color: rgba(255, 255, 255, 0) #fff rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
    margin-top: -4px;
    margin-left: -8px;
}

.w-widget-twitter-count-shim:not(.w--vertical).w--large:after {
    border-width: 5px;
    margin-top: -5px;
    margin-left: -9px;
}

.w-widget-twitter-count-shim.w--vertical {
    width: 61px;
    height: 33px;
    margin-bottom: 8px;
}

.w-widget-twitter-count-shim.w--vertical:before,
.w-widget-twitter-count-shim.w--vertical:after {
    content: " ";
    height: 0;
    width: 0;
    pointer-events: none;
    border: solid rgba(0, 0, 0, 0);
    position: absolute;
    top: 100%;
    left: 50%;
}

.w-widget-twitter-count-shim.w--vertical:before {
    border-width: 5px;
    border-color: #5d6c7b rgba(117, 134, 150, 0) rgba(117, 134, 150, 0);
    margin-left: -5px;
}

.w-widget-twitter-count-shim.w--vertical:after {
    border-width: 4px;
    border-color: #fff rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
    margin-left: -4px;
}

.w-widget-twitter-count-shim.w--vertical .w-widget-twitter-count-inner {
    font-size: 18px;
    line-height: 22px;
}

.w-widget-twitter-count-shim.w--vertical.w--large {
    width: 76px;
}

.w-background-video {
    height: 500px;
    color: #fff;
    position: relative;
    overflow: hidden;
}

.w-background-video>video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -100;
    background-position: 50%;
    background-size: cover;
    margin: auto;
    position: absolute;
    top: -100%;
    bottom: -100%;
    left: -100%;
    right: -100%;
}

.w-background-video>video::-webkit-media-controls-start-playback-button {
    -webkit-appearance: none;
    display: none !important;
}

.w-background-video--control {
    background-color: rgba(0, 0, 0, 0);
    padding: 0;
    position: absolute;
    bottom: 1em;
    right: 1em;
}

.w-background-video--control>[hidden] {
    display: none !important;
}

.w-slider {
    height: 300px;
    text-align: center;
    clear: both;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    tap-highlight-color: rgba(0, 0, 0, 0);
    background: #ddd;
    position: relative;
}

.w-slider-mask {
    z-index: 1;
    height: 100%;
    white-space: nowrap;
    display: block;
    position: relative;
    left: 0;
    right: 0;
    overflow: hidden;
}

.w-slide {
    vertical-align: top;
    width: 100%;
    height: 100%;
    white-space: normal;
    text-align: left;
    display: inline-block;
    position: relative;
}

.w-slider-nav {
    z-index: 2;
    height: 40px;
    text-align: center;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    tap-highlight-color: rgba(0, 0, 0, 0);
    margin: auto;
    padding-top: 10px;
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
}

.w-slider-nav.w-round>div {
    border-radius: 100%;
}

.w-slider-nav.w-num>div {
    width: auto;
    height: auto;
    font-size: inherit;
    line-height: inherit;
    padding: .2em .5em;
}

.w-slider-nav.w-shadow>div {
    box-shadow: 0 0 3px rgba(51, 51, 51, .4);
}

.w-slider-nav-invert {
    color: #fff;
}

.w-slider-nav-invert>div {
    background-color: rgba(34, 34, 34, .4);
}

.w-slider-nav-invert>div.w-active {
    background-color: #222;
}

.w-slider-dot {
    width: 1em;
    height: 1em;
    cursor: pointer;
    background-color: rgba(255, 255, 255, .4);
    margin: 0 3px .5em;
    transition: background-color .1s, color .1s;
    display: inline-block;
    position: relative;
}

.w-slider-dot.w-active {
    background-color: #fff;
}

.w-slider-dot:focus {
    outline: none;
    box-shadow: 0 0 0 2px #fff;
}

.w-slider-dot:focus.w-active {
    box-shadow: none;
}

.w-slider-arrow-left,
.w-slider-arrow-right {
    width: 80px;
    cursor: pointer;
    color: #fff;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: auto;
    font-size: 40px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}

.w-slider-arrow-left [class^="w-icon-"],
.w-slider-arrow-right [class^="w-icon-"],
.w-slider-arrow-left [class*=" w-icon-"],
.w-slider-arrow-right [class*=" w-icon-"] {
    position: absolute;
}

.w-slider-arrow-left:focus,
.w-slider-arrow-right:focus {
    outline: 0;
}

.w-slider-arrow-left {
    z-index: 3;
    right: auto;
}

.w-slider-arrow-right {
    z-index: 4;
    left: auto;
}

.w-icon-slider-left,
.w-icon-slider-right {
    width: 1em;
    height: 1em;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.w-slider-aria-label {
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    border: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
}

.w-slider-force-show {
    display: block !important;
}

.w-dropdown {
    text-align: left;
    z-index: 900;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    position: relative;
}

.w-dropdown-btn,
.w-dropdown-toggle,
.w-dropdown-link {
    vertical-align: top;
    color: #222;
    text-align: left;
    white-space: nowrap;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    text-decoration: none;
    position: relative;
}

.w-dropdown-toggle {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    padding-right: 40px;
    display: inline-block;
}

.w-dropdown-toggle:focus {
    outline: 0;
}

.w-icon-dropdown-toggle {
    width: 1em;
    height: 1em;
    margin: auto 20px auto auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
}

.w-dropdown-list {
    min-width: 100%;
    background: #ddd;
    display: none;
    position: absolute;
}

.w-dropdown-list.w--open {
    display: block;
}

.w-dropdown-link {
    color: #222;
    padding: 10px 20px;
    display: block;
}

.w-dropdown-link.w--current {
    color: #0082f3;
}

.w-dropdown-link:focus {
    outline: 0;
}

@media screen and (max-width: 767px) {
    .w-nav-brand {
        padding-left: 10px;
    }
}

.w-lightbox-backdrop {
    cursor: auto;
    letter-spacing: normal;
    text-indent: 0;
    text-shadow: none;
    text-transform: none;
    visibility: visible;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    color: #fff;
    text-align: center;
    z-index: 2000;
    opacity: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-tap-highlight-color: transparent;
    background: rgba(0, 0, 0, .9);
    outline: 0;
    font-family: Helvetica Neue, Helvetica, Ubuntu, Segoe UI, Verdana, sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.2;
    list-style: disc;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-transform: translate(0);
}

.w-lightbox-backdrop,
.w-lightbox-container {
    height: 100%;
    -webkit-overflow-scrolling: touch;
    overflow: auto;
}

.w-lightbox-content {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.w-lightbox-view {
    width: 100vw;
    height: 100vh;
    opacity: 0;
    position: absolute;
}

.w-lightbox-view:before {
    content: "";
    height: 100vh;
}

.w-lightbox-group,
.w-lightbox-group .w-lightbox-view,
.w-lightbox-group .w-lightbox-view:before {
    height: 86vh;
}

.w-lightbox-frame,
.w-lightbox-view:before {
    vertical-align: middle;
    display: inline-block;
}

.w-lightbox-figure {
    margin: 0;
    position: relative;
}

.w-lightbox-group .w-lightbox-figure {
    cursor: pointer;
}

.w-lightbox-img {
    width: auto;
    height: auto;
    max-width: none;
}

.w-lightbox-image {
    float: none;
    max-width: 100vw;
    max-height: 100vh;
    display: block;
}

.w-lightbox-group .w-lightbox-image {
    max-height: 86vh;
}

.w-lightbox-caption {
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: rgba(0, 0, 0, .4);
    padding: .5em 1em;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}

.w-lightbox-embed {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.w-lightbox-control {
    width: 4em;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px;
    transition: all .3s;
    position: absolute;
    top: 0;
}

.w-lightbox-left {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0yMCAwIDI0IDQwIiB3aWR0aD0iMjQiIGhlaWdodD0iNDAiPjxnIHRyYW5zZm9ybT0icm90YXRlKDQ1KSI+PHBhdGggZD0ibTAgMGg1djIzaDIzdjVoLTI4eiIgb3BhY2l0eT0iLjQiLz48cGF0aCBkPSJtMSAxaDN2MjNoMjN2M2gtMjZ6IiBmaWxsPSIjZmZmIi8+PC9nPjwvc3ZnPg==");
    display: none;
    bottom: 0;
    left: 0;
}

.w-lightbox-right {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii00IDAgMjQgNDAiIHdpZHRoPSIyNCIgaGVpZ2h0PSI0MCI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48cGF0aCBkPSJtMC0waDI4djI4aC01di0yM2gtMjN6IiBvcGFjaXR5PSIuNCIvPjxwYXRoIGQ9Im0xIDFoMjZ2MjZoLTN2LTIzaC0yM3oiIGZpbGw9IiNmZmYiLz48L2c+PC9zdmc+");
    display: none;
    bottom: 0;
    right: 0;
}

.w-lightbox-close {
    height: 2.6em;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii00IDAgMTggMTciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxNyI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48cGF0aCBkPSJtMCAwaDd2LTdoNXY3aDd2NWgtN3Y3aC01di03aC03eiIgb3BhY2l0eT0iLjQiLz48cGF0aCBkPSJtMSAxaDd2LTdoM3Y3aDd2M2gtN3Y3aC0zdi03aC03eiIgZmlsbD0iI2ZmZiIvPjwvZz48L3N2Zz4=");
    background-size: 18px;
    right: 0;
}

.w-lightbox-strip {
    white-space: nowrap;
    padding: 0 1vh;
    line-height: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-x: auto;
    overflow-y: hidden;
}

.w-lightbox-item {
    width: 10vh;
    box-sizing: content-box;
    cursor: pointer;
    padding: 2vh 1vh;
    display: inline-block;
    -webkit-transform: translate3d(0, 0, 0);
}

.w-lightbox-active {
    opacity: .3;
}

.w-lightbox-thumbnail {
    height: 10vh;
    background: #222;
    position: relative;
    overflow: hidden;
}

.w-lightbox-thumbnail-image {
    position: absolute;
    top: 0;
    left: 0;
}

.w-lightbox-thumbnail .w-lightbox-tall {
    width: 100%;
    top: 50%;
    transform: translate(0, -50%);
}

.w-lightbox-thumbnail .w-lightbox-wide {
    height: 100%;
    left: 50%;
    transform: translate(-50%);
}

.w-lightbox-spinner {
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    border: 5px solid rgba(0, 0, 0, .4);
    border-radius: 50%;
    margin-top: -20px;
    margin-left: -20px;
    animation: .8s linear infinite spin;
    position: absolute;
    top: 50%;
    left: 50%;
}

.w-lightbox-spinner:after {
    content: "";
    border: 3px solid rgba(0, 0, 0, 0);
    border-bottom-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: -4px;
    bottom: -4px;
    left: -4px;
    right: -4px;
}

.w-lightbox-hide {
    display: none;
}

.w-lightbox-noscroll {
    overflow: hidden;
}

@media (min-width: 768px) {
    .w-lightbox-content {
        height: 96vh;
        margin-top: 2vh;
    }
    .w-lightbox-view,
    .w-lightbox-view:before {
        height: 96vh;
    }
    .w-lightbox-group,
    .w-lightbox-group .w-lightbox-view,
    .w-lightbox-group .w-lightbox-view:before {
        height: 84vh;
    }
    .w-lightbox-image {
        max-width: 96vw;
        max-height: 96vh;
    }
    .w-lightbox-group .w-lightbox-image {
        max-width: 82.3vw;
        max-height: 84vh;
    }
    .w-lightbox-left,
    .w-lightbox-right {
        opacity: .5;
        display: block;
    }
    .w-lightbox-close {
        opacity: .8;
    }
    .w-lightbox-control:hover {
        opacity: 1;
    }
}

.w-lightbox-inactive,
.w-lightbox-inactive:hover {
    opacity: 0;
}

.w-richtext:before,
.w-richtext:after {
    content: " ";
    grid-area: 1 / 1 / 2 / 2;
    display: table;
}

.w-richtext:after {
    clear: both;
}

.w-richtext[contenteditable="true"]:before,
.w-richtext[contenteditable="true"]:after {
    white-space: initial;
}

.w-richtext ol,
.w-richtext ul {
    overflow: hidden;
}

.w-richtext .w-richtext-figure-selected.w-richtext-figure-type-video div:after,
.w-richtext .w-richtext-figure-selected[data-rt-type="video"] div:after,
.w-richtext .w-richtext-figure-selected.w-richtext-figure-type-image div,
.w-richtext .w-richtext-figure-selected[data-rt-type="image"] div {
    outline: 2px solid #2895f7;
}

.w-richtext figure.w-richtext-figure-type-video>div:after,
.w-richtext figure[data-rt-type="video"]>div:after {
    content: "";
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.w-richtext figure {
    max-width: 60%;
    position: relative;
}

.w-richtext figure>div:before {
    cursor: default !important;
}

.w-richtext figure img {
    width: 100%;
}

.w-richtext figure figcaption.w-richtext-figcaption-placeholder {
    opacity: .6;
}

.w-richtext figure div {
    color: rgba(0, 0, 0, 0);
    font-size: 0;
}

.w-richtext figure.w-richtext-figure-type-image,
.w-richtext figure[data-rt-type="image"] {
    display: table;
}

.w-richtext figure.w-richtext-figure-type-image>div,
.w-richtext figure[data-rt-type="image"]>div {
    display: inline-block;
}

.w-richtext figure.w-richtext-figure-type-image>figcaption,
.w-richtext figure[data-rt-type="image"]>figcaption {
    caption-side: bottom;
    display: table-caption;
}

.w-richtext figure.w-richtext-figure-type-video,
.w-richtext figure[data-rt-type="video"] {
    width: 60%;
    height: 0;
}

.w-richtext figure.w-richtext-figure-type-video iframe,
.w-richtext figure[data-rt-type="video"] iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.w-richtext figure.w-richtext-figure-type-video>div,
.w-richtext figure[data-rt-type="video"]>div {
    width: 100%;
}

.w-richtext figure.w-richtext-align-center {
    clear: both;
    margin-left: auto;
    margin-right: auto;
}

.w-richtext figure.w-richtext-align-center.w-richtext-figure-type-image>div,
.w-richtext figure.w-richtext-align-center[data-rt-type="image"]>div {
    max-width: 100%;
}

.w-richtext figure.w-richtext-align-normal {
    clear: both;
}

.w-richtext figure.w-richtext-align-fullwidth {
    width: 100%;
    max-width: 100%;
    text-align: center;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.w-richtext figure.w-richtext-align-fullwidth>div {
    padding-bottom: inherit;
    display: inline-block;
}

.w-richtext figure.w-richtext-align-fullwidth>figcaption {
    display: block;
}

.w-richtext figure.w-richtext-align-floatleft {
    float: left;
    clear: none;
    margin-right: 15px;
}

.w-richtext figure.w-richtext-align-floatright {
    float: right;
    clear: none;
    margin-left: 15px;
}

.w-nav {
    z-index: 1000;
    background: #ddd;
    position: relative;
}

.w-nav:before,
.w-nav:after {
    content: " ";
    grid-area: 1 / 1 / 2 / 2;
    display: table;
}

.w-nav:after {
    clear: both;
}

.w-nav-brand {
    float: left;
    color: #333;
    text-decoration: none;
    position: relative;
}

.w-nav-link {
    vertical-align: top;
    color: #222;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    text-decoration: none;
    display: inline-block;
    position: relative;
}

.w-nav-link.w--current {
    color: #0082f3;
}

.w-nav-menu {
    float: right;
    position: relative;
}

[data-nav-menu-open] {
    text-align: center;
    min-width: 200px;
    background: #c8c8c8;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    overflow: visible;
    display: block !important;
}

.w--nav-link-open {
    display: block;
    position: relative;
}

.w-nav-overlay {
    width: 100%;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    overflow: hidden;
}

.w-nav-overlay [data-nav-menu-open] {
    top: 0;
}

.w-nav[data-animation="over-left"] .w-nav-overlay {
    width: auto;
}

.w-nav[data-animation="over-left"] .w-nav-overlay,
.w-nav[data-animation="over-left"] [data-nav-menu-open] {
    z-index: 1;
    top: 0;
    right: auto;
}

.w-nav[data-animation="over-right"] .w-nav-overlay {
    width: auto;
}

.w-nav[data-animation="over-right"] .w-nav-overlay,
.w-nav[data-animation="over-right"] [data-nav-menu-open] {
    z-index: 1;
    top: 0;
    left: auto;
}

.w-nav-button {
    float: right;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 18px;
    font-size: 24px;
    display: none;
    position: relative;
}

.w-nav-button:focus {
    outline: 0;
}

.w-nav-button.w--open {
    color: #fff;
    background-color: #c8c8c8;
}

.w-nav[data-collapse="all"] .w-nav-menu {
    display: none;
}

.w-nav[data-collapse="all"] .w-nav-button,
.w--nav-dropdown-open,
.w--nav-dropdown-toggle-open {
    display: block;
}

.w--nav-dropdown-list-open {
    position: static;
}

@media screen and (max-width: 991px) {
    .w-nav[data-collapse="medium"] .w-nav-menu {
        display: none;
    }
    .w-nav[data-collapse="medium"] .w-nav-button {
        display: block;
    }
}

@media screen and (max-width: 767px) {
    .w-nav[data-collapse="small"] .w-nav-menu {
        display: none;
    }
    .w-nav[data-collapse="small"] .w-nav-button {
        display: block;
    }
    .w-nav-brand {
        padding-left: 10px;
    }
}

@media screen and (max-width: 479px) {
    .w-nav[data-collapse="tiny"] .w-nav-menu {
        display: none;
    }
    .w-nav[data-collapse="tiny"] .w-nav-button {
        display: block;
    }
}

.w-tabs {
    position: relative;
}

.w-tabs:before,
.w-tabs:after {
    content: " ";
    grid-area: 1 / 1 / 2 / 2;
    display: table;
}

.w-tabs:after {
    clear: both;
}

.w-tab-menu {
    position: relative;
}

.w-tab-link {
    vertical-align: top;
    text-align: left;
    cursor: pointer;
    color: #222;
    background-color: #ddd;
    padding: 9px 30px;
    text-decoration: none;
    display: inline-block;
    position: relative;
}

.w-tab-link.w--current {
    background-color: #c8c8c8;
}

.w-tab-link:focus {
    outline: 0;
}

.w-tab-content {
    display: block;
    position: relative;
    overflow: hidden;
}

.w-tab-pane {
    display: none;
    position: relative;
}

.w--tab-active {
    display: block;
}

@media screen and (max-width: 479px) {
    .w-tab-link {
        display: block;
    }
}

.w-ix-emptyfix:after {
    content: "";
}

@keyframes spin {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

.w-dyn-empty {
    background-color: #ddd;
    padding: 10px;
}

.w-dyn-hide,
.w-dyn-bind-empty,
.w-condition-invisible {
    display: none !important;
}

.wf-layout-layout {
    display: grid;
}

.w-layout-grid {
    grid-row-gap: 16px;
    grid-column-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
}

.uui-navbar08_menu-button {
    padding: 0;
}

.uui-button {
    grid-column-gap: .5rem;
    color: #56bd88;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: 2px solid #56bd88;
    border-radius: 8rem;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-decoration: none;
    transition: all .3s;
    display: block;
    box-shadow: 0 1px 2px rgba(16, 24, 40, .05);
}

.uui-button:hover {
    color: #fff;
    background-color: #56bd88;
    border-color: #fff;
}

.uui-button:focus {
    color: #fff;
    background-color: #56bd88;
    box-shadow: 0 1px 2px rgba(16, 24, 40, .05), 0 0 0 4px #f4ebff;
}

.uui-button.button-inverse {
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    border-color: #fff;
}

.uui-button.overlap {
    margin-left: -60px;
}

.uui-button-tertiary-gray {
    grid-column-gap: .5rem;
    color: #475467;
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: .5rem;
    justify-content: center;
    align-items: center;
    padding: .625rem 1.125rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    text-decoration: none;
    transition: all .3s;
    display: flex;
}

.uui-button-tertiary-gray:hover {
    color: #344054;
    background-color: #f9fafb;
}

.uui-button-secondary-gray {
    grid-column-gap: .5rem;
    color: #344054;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    border-radius: .5rem;
    justify-content: center;
    align-items: center;
    padding: .625rem 1.125rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    text-decoration: none;
    transition: all .3s;
    display: flex;
    box-shadow: 0 1px 2px rgba(16, 24, 40, .05);
}

.uui-button-secondary-gray:hover {
    color: #1d2939;
    background-color: #f9fafb;
}

.uui-button-secondary-gray:focus {
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(16, 24, 40, .05), 0 0 0 4px #f2f4f7;
}

.uui-button-secondary-gray.show-tablet,
.show-tablet {
    display: none;
}

.uui-navbar08_button-wrapper {
    grid-column-gap: .75rem;
    grid-row-gap: .75rem;
    align-items: center;
    display: flex;
}

.uui-navbar08_menu-right {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    align-items: center;
    display: flex;
}

.uui-navbar08_link {
    color: #538fb2;
    align-items: center;
    padding: .75rem 1rem;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 16px;
    font-weight: 400;
    transition: all .3s;
}

.uui-navbar08_link:hover {
    color: #315074;
}

.uui-navbar08_link.w--current {
    color: #000;
}

.uui-text-size-small {
    color: #475467;
    letter-spacing: normal;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: .875rem;
    line-height: 1.5;
}

.uui-text-size-small.text-color-gray500 {
    color: #667085;
    font-family: Rubik variablefont wght, sans-serif;
    font-weight: 300;
}

.uui-navbar08_item-heading {
    color: #538fb2;
    margin-bottom: .25rem;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 16px;
    font-weight: 300;
}

.uui-navbar08_item-heading.margin-bottom-0 {
    margin-bottom: 0;
}

.uui-navbar08_item-right {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-top: 2px;
    display: flex;
}

.uui-icon-1x1-xsmall {
    width: 1.5rem;
    height: 1.5rem;
}

.uui-navbar08_icon-wrapper {
    color: #7f56d9;
    flex: none;
}

.uui-navbar08_dropdown-link {
    grid-column-gap: 1rem;
    grid-row-gap: 0px;
    border-radius: .5rem;
    grid-template-rows: auto;
    grid-template-columns: max-content 1fr;
    grid-auto-columns: 1fr;
    align-items: flex-start;
    padding: .75rem;
    text-decoration: none;
    transition: all .3s;
    display: grid;
}

.uui-navbar08_dropdown-link:hover {
    background-color: #f9fafb;
}

.uui-badge-small-success {
    grid-column-gap: .25rem;
    color: #027a48;
    white-space: nowrap;
    mix-blend-mode: multiply;
    background-color: #ecfdf3;
    border-radius: 10rem;
    align-items: center;
    padding: .125rem .5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: .75rem;
    font-weight: 500;
    display: flex;
}

.uui-navbar08_text-wrapper {
    grid-column-gap: .5rem;
    grid-row-gap: 0px;
    align-items: center;
    margin-bottom: .25rem;
    display: flex;
}

.uui-navbar08_heading {
    color: #7f56d9;
    letter-spacing: normal;
    margin-top: 0;
    margin-bottom: .25rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: .875rem;
    font-weight: 600;
}

.uui-navbar08_dropdown-link-list {
    grid-column-gap: 0px;
    grid-row-gap: .5rem;
    grid-template-rows: max-content;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    grid-auto-columns: 1fr;
    display: grid;
}

.uui-navbar08_dropdown-content {
    grid-column-gap: 2rem;
    grid-row-gap: 0px;
    flex: 1;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    display: grid;
}

.uui-navbar08_dropdown-content._2-col {
    grid-template-columns: 1fr;
}

.uui-navbar08_dropdown-list {
    box-shadow: 0 12px 16px -4px rgba(0, 0, 0, .08), 0 4px 6px -2px rgba(16, 24, 40, .03);
}

.uui-navbar08_dropdown-list.w--open {
    width: 15rem;
    background-color: #fff;
    border: 1px solid #eaecf0;
    border-radius: .75rem;
    padding: 1.25rem;
    left: -40%;
}

.uui-navbar08_dropdown-list.wide.w--open {
    width: 16rem;
}

.uui-dropdown-icon {
    width: 1.25rem;
    height: 1.25rem;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    display: flex;
    position: absolute;
    top: auto;
    bottom: auto;
    left: auto;
    right: 0%;
}

.uui-navbar08_dropdown-toggle {
    color: #538fb2;
    align-items: center;
    padding: .75rem 2.5rem .75rem 1rem;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 16px;
    font-weight: 400;
    transition: all .3s;
    display: flex;
}

.uui-navbar08_dropdown-toggle:hover {
    color: #315074;
}

.uui-navbar08_menu {
    flex: 1;
    justify-content: center;
    align-items: center;
    margin-left: 1.5rem;
    display: flex;
    position: static;
}

.uui-logo_image {
    width: auto;
    height: 100%;
    flex: none;
    display: none;
}

.uui-logo_logotype {
    width: auto;
    height: 100%;
    flex: none;
}

.uui-logo_logomark-dot {
    z-index: 1;
    width: 50%;
    height: 50%;
    background-image: linear-gradient(26.5deg, #6941c6, #53389e);
    border-radius: 50%;
    position: relative;
}

.uui-logo_logomark-blur {
    z-index: 2;
    width: 100%;
    height: 50%;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background-color: rgba(255, 255, 255, .2);
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
    position: absolute;
    top: 50%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    overflow: hidden;
}

.uui-styleguide_logomark-bg {
    width: 180px;
    height: 34px;
    border-radius: 8px;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

.uui-logo_logomark {
    width: 180px;
    height: 34px;
    border: .1px #dae0e8;
    border-radius: .5rem;
    flex: none;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    overflow: hidden;
}

.uui-logo_component {
    width: auto;
    height: 2rem;
    flex-wrap: nowrap;
    display: flex;
}

.uui-navbar08_logo-link {
    padding-left: 0;
}

.uui-navbar08_container {
    width: 100%;
    height: 100%;
    max-width: 80rem;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.uui-navbar08_component {
    min-height: 5rem;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 20rem;
    align-items: center;
    margin-top: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
    box-shadow: 0 1px 11px rgba(13, 75, 112, .1);
}

.uui-heroheader08_image {
    width: 100%;
    height: 40rem;
    object-fit: cover;
}

.uui-text-size-medium {
    color: #475467;
    letter-spacing: normal;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5;
}

.uui-ratings_number {
    color: #344054;
    letter-spacing: normal;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1rem;
    font-weight: 600;
}

.uui-ratings_stars {
    width: 100%;
    height: 1.25rem;
    margin-right: .5rem;
}

.ratings_stars-wrapper {
    justify-content: flex-start;
    align-items: center;
    margin-bottom: .125rem;
    display: flex;
}

.ratings_wrapper {
    margin-left: 1rem;
}

.uui-avatar-group_item {
    width: 2.75rem;
    height: 2.75rem;
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 50%;
    margin-right: -1rem;
}

.uui-avatar-group_component {
    grid-column-gap: 0rem;
    flex-wrap: nowrap;
    align-items: center;
    padding-right: 1rem;
    display: flex;
}

.uui-ratings_component {
    align-items: center;
    display: flex;
}

.uui-text-style-link {
    color: #475467;
    text-decoration: underline;
    transition: color .3s;
}

.uui-text-style-link:hover {
    color: #344054;
}

.uui-form_input {
    height: auto;
    min-height: 2.75rem;
    color: #101828;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    border-radius: .5rem;
    margin-bottom: 0;
    padding: .5rem .875rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    transition: all .3s;
    box-shadow: 0 1px 2px rgba(16, 24, 40, .05);
}

.uui-form_input:focus {
    color: #101828;
    border-color: #d6bbfb;
    box-shadow: 0 1px 2px rgba(16, 24, 40, .05), 0 0 0 4px #f4ebff;
}

.uui-form_input::-ms-input-placeholder {
    color: #667085;
}

.uui-form_input::placeholder {
    color: #667085;
}

.uui-signup-form_wrapper {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr max-content;
    grid-auto-columns: 1fr;
    margin-bottom: .375rem;
    display: grid;
}

.uui-heroheader08_form {
    max-width: 30rem;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}

.uui-text-size-xlarge {
    color: #315074;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
}

.uui-max-width-small {
    width: 100%;
    max-width: 30rem;
}

.uui-space-small {
    width: 100%;
    min-height: 1.5rem;
}

.uui-heading-xlarge {
    color: #315074;
    letter-spacing: -.01em;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Space Grotesk, sans-serif;
    font-size: 60px;
    font-weight: 400;
    line-height: 98%;
}

.uui-heroheader08_component {
    grid-column-gap: 8rem;
    grid-row-gap: 8rem;
    grid-template-rows: auto;
    grid-template-columns: 1.5fr 2fr;
    grid-auto-columns: 1fr;
    align-items: center;
    display: grid;
}

.uui-padding-vertical-xhuge {
    padding-top: 7rem;
    padding-bottom: 7rem;
}

.uui-padding-vertical-xhuge.hp-top {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.uui-padding-vertical-xhuge.no-padding-bottom {
    padding-bottom: 4rem;
}

.uui-container-large {
    width: 100%;
    max-width: 70rem;
    margin-left: auto;
    margin-right: auto;
}

.uui-page-padding {
    background-color: rgba(0, 0, 0, 0);
    padding-left: 2rem;
    padding-right: 2rem;
}

.uui-page-padding.dark-blue {
    background-color: #315074;
}

.uui-section_heroheader08 {
    background-color: rgba(0, 0, 0, 0);
    background-image: url("https://uploads-ssl.webflow.com/6405f18ff0cf3cb688b7fba9/6407a11a0244def6bd32351c_BG%20Gradient.svg");
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.uui-section_heroheader08.mobile {
    display: none;
}

.uui-button-wrapper {
    display: flex;
}

.uui-button-row {
    grid-column-gap: .75rem;
    grid-row-gap: .75rem;
    flex-wrap: wrap;
    align-items: center;
    display: flex;
}

.uui-button-row.button-row-center {
    justify-content: center;
}

.uui-space-medium {
    width: 100%;
    min-height: 2rem;
}

.uui-text-size-large {
    color: #315074;
    letter-spacing: normal;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 27px;
}

.uui-space-xsmall {
    width: 100%;
    min-height: 1rem;
}

.uui-heading-medium {
    color: #315074;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Space Grotesk, sans-serif;
    font-size: 34px;
    font-weight: 400;
    line-height: 43px;
}

.uui-heading-medium.white {
    color: #fff;
    margin-top: 3rem;
}

.uui-heading-medium.white.narrow {
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.uui-heading-medium.search-form {
    color: #fff;
    background-image: none;
    font-size: 24px;
}

.uui-heading-medium.center {
    text-align: center;
}

.uui-heading-medium._4-up {
    color: #538fb2;
    text-align: center;
    font-size: 24px;
    line-height: 30px;
}

.uui-heading-medium._4-up.green {
    color: #56bd88;
    font-size: 40px;
    line-height: 40px;
}

.uui-heading-medium._4-up.red {
    color: #ef6f6c;
    font-size: 40px;
    line-height: 40px;
}

.uui-heading-medium.centered,
.uui-text-align-center {
    text-align: center;
}

.uui-container-small {
    width: 100%;
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
}

.uui-section_cta08 {
    background-color: #fff;
    position: relative;
}

.uui-section_cta08.hp-press-header {
    margin-top: 100px;
}

.uui-layout21_item-icon-wrapper {
    align-self: flex-start;
    margin-right: .75rem;
}

.uui-layout21_item {
    display: flex;
}

.uui-layout21_item-list {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    margin-top: 2rem;
    margin-bottom: 2.5rem;
    display: grid;
}

.uui-heading-subheading {
    color: #315074;
    margin-bottom: 0;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.5;
}

.uui-layout20_component-copy {
    grid-column-gap: 4rem;
    grid-row-gap: 4rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    align-items: start;
    display: grid;
}

.uui-button-icon {
    width: 1.25rem;
    height: 1.25rem;
    min-height: 1.25rem;
    min-width: 1.25rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
}

.uui-button-link {
    grid-column-gap: .5rem;
    color: #6941c6;
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0);
    justify-content: center;
    align-items: center;
    padding: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    text-decoration: none;
    transition: all .3s;
    display: flex;
}

.uui-button-link:hover {
    color: #53389e;
}

.uui-button-link.uui-button {
    color: #56bd88;
    padding: 10px 30px;
    display: flex;
}

.uui-button-link.uui-button:hover,
.uui-button-link.uui-button.button-inverse.white {
    color: #fff;
}

.uui-button-link.uui-button.blog-excerpt {
    max-width: 50%;
}

.uui-space-xxsmall {
    width: 100%;
    min-height: .5rem;
}

.uui-heading-xxsmall {
    color: #101828;
    letter-spacing: normal;
    margin-top: 0;
    margin-bottom: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5;
}

.uui-space-xxlarge {
    width: 100%;
    min-height: 4rem;
}

.icon-featured-square-large {
    width: 3rem;
    height: 3rem;
    color: #fff;
    background-color: #9e77ed;
    border-radius: .625rem;
    justify-content: center;
    align-items: center;
    display: flex;
}

.uui-layout72_item {
    width: 100%;
    background-color: #f9fafb;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1.5rem;
    display: flex;
}

.uui-layout72_component {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    align-items: stretch;
    justify-items: start;
    display: grid;
}

.uui-layout94_item {
    width: 100%;
    background-color: #f9fafb;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1.5rem;
    display: flex;
}

.uui-layout94_list {
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    align-items: stretch;
    justify-items: start;
    margin-top: 4rem;
    margin-bottom: 4rem;
    display: grid;
}

.uui-max-width-large {
    width: 100%;
    max-width: 48rem;
}

.uui-layout94_component {
    flex-direction: column;
    align-items: flex-start;
    display: flex;
}

.uui-cta09_component {
    background-color: rgba(0, 0, 0, 0);
    background-image: url("https://uploads-ssl.webflow.com/6405f18ff0cf3cb688b7fba9/640f52801e8e1f40d78531f1_trees.svg");
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 0;
    padding: 4rem;
}

.uui-cta09_component.bottom-cta {
    padding-top: 1rem;
}

.uui-section_cta09 {
    background-color: #fff;
    position: relative;
}

.uui-footer03_legal-link {
    color: #56bd88;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: .875rem;
    font-weight: 300;
    text-decoration: none;
    transition: all .3s;
}

.uui-footer03_legal-link:hover {
    color: #475467;
}

.uui-footer03_legal-link.border {
    border-right: 1px solid #d0d5dd;
    padding-right: 20px;
}

.uui-footer03_legal-list {
    grid-column-gap: 1rem;
    grid-row-gap: 0rem;
    white-space: normal;
    grid-template-rows: auto;
    grid-template-columns: max-content;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    justify-content: center;
    display: grid;
}

.uui-footer03_bottom-wrapper {
    border-top: 1px solid #eaecf0;
    justify-content: space-between;
    align-items: center;
    margin-top: 4rem;
    padding-top: 2rem;
    display: flex;
}

.uui-footer03_link {
    grid-column-gap: .5rem;
    color: #667085;
    flex-wrap: nowrap;
    align-items: center;
    padding-top: .75rem;
    padding-bottom: .5rem;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 14px;
    font-weight: 300;
    text-decoration: none;
    transition: all .3s;
    display: flex;
}

.uui-footer03_link:hover {
    color: #538fb2;
}

.uui-footer03_link.no-padding {
    padding-top: 0;
}

.uui-footer03_link-list {
    flex-direction: column;
    grid-template-rows: auto;
    grid-template-columns: 100%;
    grid-auto-columns: 100%;
    align-items: flex-start;
    justify-items: start;
    margin-bottom: 20px;
    display: flex;
}

.uui-footer03_menu-wrapper {
    grid-column-gap: 2rem;
    grid-row-gap: 0rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: start;
}

.uui-footer03_menu-wrapper.mobile {
    display: none;
}

.social-icon {
    width: 1.5rem;
    height: 1.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
}

.uui-footer03_social-link {
    color: #56bd88;
    align-items: center;
    font-size: 14px;
    text-decoration: none;
    transition: all .3s;
    display: flex;
}

.uui-footer03_social-link:hover {
    color: #667085;
}

.uui-footer03_social-list {
    grid-column-gap: 1.5rem;
    grid-row-gap: 0rem;
    white-space: normal;
    grid-template-rows: auto;
    grid-template-columns: max-content;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    align-items: start;
    justify-items: start;
    margin-top: 2rem;
    display: grid;
}

.uui-footer03_details-wrapper {
    max-width: 20rem;
}

.uui-footer03_logo-link {
    float: none;
    margin-bottom: 2rem;
    display: block;
}

.uui-footer03_top-wrapper {
    grid-column-gap: 4vw;
    grid-template-rows: auto;
    grid-template-columns: .5fr .5fr;
    align-items: start;
    justify-items: start;
}

.uui-padding-vertical-xlarge {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.image {
    margin-left: 0;
}

.image.offset-left {
    margin-left: -16px;
}

.footer-header {
    color: #538fb2;
    padding-bottom: 5px;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.search-form-wrapper {
    background-color: #538fb2;
    border-radius: 20px;
    margin-left: 40px;
    margin-right: 40px;
    padding: 20px;
}

.search-form-wrapper.demo-form {
    background-color: #315074;
}

.text-field {
    height: 50px;
    border-radius: 40px;
    margin-bottom: 20px;
    padding-left: 30px;
    font-family: Rubik variablefont wght, sans-serif;
    font-weight: 300;
}

.text-field.no-padding {
    margin-bottom: 0;
}

.text-field-2 {
    border-radius: 20px;
}

.select-field {
    height: 50px;
    color: #98a2b3;
    border-radius: 40px;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 12px;
    font-family: Rubik variablefont wght, sans-serif;
}

.search-form {
    background-image: url("https://uploads-ssl.webflow.com/6405f18ff0cf3cb688b7fba9/640f58afd6df0e6fd893199f_BG%20Shape%20-%20Search.svg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
}

.uui-button-2 {
    grid-column-gap: .5rem;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #7f56d9;
    border: 1px solid #7f56d9;
    border-radius: .5rem;
    justify-content: center;
    align-items: center;
    padding: .625rem 1.125rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    text-decoration: none;
    transition: all .3s;
    display: flex;
    box-shadow: 0 1px 2px rgba(16, 24, 40, .05);
}

.uui-button-2:hover {
    background-color: #6941c6;
    border-color: #6941c6;
}

.uui-button-2:focus {
    background-color: #7f56d9;
    box-shadow: 0 1px 2px rgba(16, 24, 40, .05), 0 0 0 4px #f4ebff;
}

.uui-button-row-2 {
    grid-column-gap: .75rem;
    grid-row-gap: .75rem;
    flex-wrap: wrap;
    align-items: center;
    display: flex;
}

.uui-button-row-2.button-row-center {
    justify-content: center;
}

.uui-space-small-2 {
    width: 100%;
    min-height: 1.5rem;
}

.uui-text-size-large-2 {
    color: #475467;
    letter-spacing: normal;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
}

.uui-space-xxsmall-2 {
    width: 100%;
    min-height: .5rem;
}

.uui-heading-xxsmall-2 {
    color: #101828;
    letter-spacing: normal;
    margin-top: 0;
    margin-bottom: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5;
}

.uui-avatar-group_item-2,
.uui-avatar-stack_item {
    width: 2.75rem;
    height: 2.75rem;
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 50%;
    margin-right: -1rem;
}

.uui-avatar-stack_item.featured {
    width: 3.5rem;
    height: 3.5rem;
    position: relative;
}

.avatar-stack_component {
    width: auto;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 2rem;
    margin-right: 1rem;
    display: flex;
}

.uui-text-align-center-2 {
    text-align: center;
}

.uui-faq01_cta {
    background-color: #f9fafb;
    border-radius: 1rem;
    justify-content: center;
    margin-top: 4rem;
    padding: 2rem;
    display: flex;
}

.uui-space-medium-2 {
    width: 100%;
    min-height: 2rem;
}

.uui-space-medium-2.bottom-border {
    border-bottom: 1px solid #538fb2;
}

.uui-text-size-medium-2 {
    color: #475467;
    letter-spacing: normal;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5;
}

.uui-text-size-medium-2.white {
    color: #fff;
}

.uui-faq01_answer {
    overflow: hidden;
}

.accordion-icon_vertical-line {
    width: 2px;
    height: .75rem;
    background-color: #fff;
    border-radius: 1px;
    position: absolute;
}

.accordion-icon_horizontal-line {
    width: .75rem;
    height: 2px;
    background-color: #fff;
    border-radius: 1px;
    position: absolute;
}

.accordion-icon_component {
    width: 1.5rem;
    height: 1.5rem;
    color: #98a2b3;
    background-color: #538fb2;
    border: 2px solid #fff;
    border-radius: 50%;
    justify-content: center;
    align-self: flex-start;
    align-items: center;
    display: flex;
    position: relative;
}

.uui-faq01_icon-wrapper {
    color: #7f56d9;
}

.uui-faq01_heading {
    color: #101828;
    letter-spacing: normal;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Space Grotesk, sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
}

.uui-faq01_heading.white {
    color: #fff;
}

.uui-faq01_question {
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    display: flex;
}

.uui-faq01_accordion {
    border-bottom: 1px solid #538fb2;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

.uui-faq01_accordion.last-item {
    border-bottom-color: rgba(0, 0, 0, 0);
}

.uui-faq01_list {
    max-width: 48rem;
    margin-left: auto;
    margin-right: auto;
}

.uui-faq01_component {
    margin-top: 4rem;
}

.uui-space-xsmall-2 {
    width: 100%;
    min-height: 1rem;
}

.uui-heading-medium-2 {
    color: #101828;
    margin-top: 0;
    margin-bottom: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 2.25rem;
    font-weight: 600;
    line-height: 1.3;
}

.uui-heading-medium-2.left {
    text-align: left;
    font-family: Space Grotesk, sans-serif;
    font-weight: 400;
}

.uui-heading-medium-2.left.white {
    color: #fff;
}

.uui-heading-subheading-2 {
    color: #315074;
    margin-bottom: .75rem;
    font-family: Space Grotesk, sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.5;
}

.uui-max-width-large-2 {
    width: 100%;
    max-width: 48rem;
}

.uui-max-width-large-2.align-center,
.align-center {
    margin-left: auto;
    margin-right: auto;
}

.uui-padding-vertical-xhuge-2 {
    padding-top: 7rem;
    padding-bottom: 7rem;
}

.uui-padding-vertical-xhuge-2.extra-top-padding {
    padding-top: 10rem;
}

.uui-container-large-2 {
    width: 100%;
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;
}

.uui-page-padding-2 {
    padding-left: 2rem;
    padding-right: 2rem;
}

.uui-section_faq01 {
    background-color: #315074;
}

.uui-button-3 {
    grid-column-gap: .5rem;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #7f56d9;
    border: 1px solid #7f56d9;
    border-radius: .5rem;
    justify-content: center;
    align-items: center;
    padding: .625rem 1.125rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    text-decoration: none;
    transition: all .3s;
    display: flex;
    box-shadow: 0 1px 2px rgba(16, 24, 40, .05);
}

.uui-button-3:hover {
    background-color: #6941c6;
    border-color: #6941c6;
}

.uui-button-3:focus {
    background-color: #7f56d9;
    box-shadow: 0 1px 2px rgba(16, 24, 40, .05), 0 0 0 4px #f4ebff;
}

.uui-button-secondary-gray-2 {
    grid-column-gap: .5rem;
    color: #344054;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    border-radius: .5rem;
    justify-content: center;
    align-items: center;
    padding: .625rem 1.125rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    text-decoration: none;
    transition: all .3s;
    display: flex;
    box-shadow: 0 1px 2px rgba(16, 24, 40, .05);
}

.uui-button-secondary-gray-2:hover {
    color: #1d2939;
    background-color: #f9fafb;
}

.uui-button-secondary-gray-2:focus {
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(16, 24, 40, .05), 0 0 0 4px #f2f4f7;
}

.uui-button-row-3 {
    grid-column-gap: .75rem;
    grid-row-gap: .75rem;
    flex-wrap: wrap;
    align-items: center;
    display: flex;
}

.uui-space-medium-3 {
    width: 100%;
    min-height: 2rem;
}

.uui-text-size-large-3 {
    color: #475467;
    letter-spacing: normal;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
}

.uui-space-xsmall-3 {
    width: 100%;
    min-height: 1rem;
}

.uui-heading-medium-3 {
    color: #315074;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Space Grotesk, sans-serif;
    font-size: 34px;
    font-weight: 400;
    line-height: 1.3;
}

.uui-heading-medium-3.white {
    color: #fff;
}

.uui-heading-medium-3.yellow-pill {
    background-color: rgba(222, 181, 118, .2);
    border-radius: 20px;
    margin-bottom: .25rem;
    margin-left: -20px;
    padding: 2px 20px;
    font-size: 24px;
    display: inline-block;
}

.uui-heading-medium-3.centered {
    text-align: center;
}

.uui-heading-medium-3.centered.padded-bottom {
    margin-bottom: 5rem;
}

.uui-heading-subheading-3 {
    color: #7f56d9;
    margin-bottom: .75rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
}

.uui-layout49_image {
    width: 40rem;
    min-width: 40rem;
    border: .25rem solid #101828;
    border-radius: .5rem;
    position: absolute;
    top: auto;
    bottom: auto;
    left: auto;
    right: 3rem;
}

.uui-layout49_image.shadow-xlarge {
    border-style: none;
}

.uui-layout49_image-wrapper {
    width: 100%;
    height: 100%;
    min-height: 33rem;
    background-color: rgba(0, 0, 0, 0);
    align-items: center;
    display: flex;
    position: relative;
    overflow: hidden;
}

.uui-layout49_component {
    grid-column-gap: 4rem;
    grid-row-gap: 4rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    align-items: center;
    display: grid;
}

.uui-padding-vertical-xhuge-3 {
    padding-top: 7rem;
    padding-bottom: 7rem;
}

.uui-padding-vertical-xhuge-3.quote-module {
    padding-top: 0;
    padding-bottom: 16rem;
}

.uui-padding-vertical-xhuge-3.extra-bottom-padding {
    padding-bottom: 12rem;
}

.uui-container-large-3 {
    width: 100%;
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;
}

.uui-page-padding-3 {
    padding-left: 4rem;
    padding-right: 4rem;
}

.uui-page-padding-3.blog-digest {
    background-image: linear-gradient(138deg, rgba(86, 189, 136, .2), #fff);
    padding-top: 60px;
    padding-bottom: 60px;
}

.uui-section_layout49 {
    background-image: linear-gradient(rgba(83, 143, 178, .05), #fff);
}

.uui-text-size-medium-3 {
    color: #315074;
    letter-spacing: normal;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 27px;
}

.uui-text-size-medium-3.lt-blue {
    color: #538fb2;
}

.uui-space-xxsmall-3 {
    width: 100%;
    min-height: .5rem;
}

.uui-heading-xxsmall-3 {
    color: #538fb2;
    letter-spacing: normal;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
}

.uui-heading-xxsmall-3.green-pill {
    color: #56bd88;
    background-color: rgba(86, 189, 136, .2);
    border-radius: 20px;
    margin-bottom: 1rem;
    padding: 2px 20px;
    font-weight: 300;
    display: inline-block;
}

.uui-space-xxlarge-2 {
    width: 100%;
    min-height: 5rem;
}

.uui-icon-1x1-xsmall-2 {
    width: 1.5rem;
    height: 1.5rem;
}

.icon-featured-square-large-2 {
    width: 3rem;
    height: 3rem;
    color: #fff;
    background-color: #9e77ed;
    border-radius: .625rem;
    justify-content: center;
    align-items: center;
    display: flex;
}

.uui-layout83_item {
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1.5rem 0;
    display: flex;
}

.uui-layout83_list {
    width: 100%;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
}

.uui-heading-medium-4 {
    color: #101828;
    margin-top: 0;
    margin-bottom: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 2.25rem;
    font-weight: 600;
    line-height: 1.3;
}

.uui-heading-medium-4.text-weight-medium {
    font-weight: 500;
}

.uui-heading-medium-4.white {
    color: #fff;
    text-align: center;
    font-family: Space Grotesk, sans-serif;
    font-size: 24px;
    font-weight: 400;
}

.uui-layout83_content {
    flex-direction: column;
    align-items: center;
}

.uui-layout83_component {
    grid-column-gap: 4rem;
    grid-row-gap: 4rem;
    grid-template-rows: auto;
    grid-template-columns: .5fr 1fr;
    grid-auto-columns: 1fr;
    align-items: start;
    justify-items: start;
    display: grid;
}

.uui-button-icon-2 {
    width: 1.25rem;
    height: 1.25rem;
    min-height: 1.25rem;
    min-width: 1.25rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
}

.uui-button-link-2 {
    grid-column-gap: .5rem;
    color: #6941c6;
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0);
    justify-content: center;
    align-items: center;
    padding: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    text-decoration: none;
    transition: all .3s;
    display: flex;
}

.uui-button-link-2:hover {
    color: #53389e;
}

.uui-space-small-3 {
    width: 100%;
    min-height: 1.5rem;
}

.uui-text-size-medium-4 {
    color: #315074;
    letter-spacing: normal;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 27px;
}

.uui-heading-xxsmall-4 {
    color: #101828;
    letter-spacing: normal;
    margin-top: 0;
    margin-bottom: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5;
}

.uui-layout72_item-content-2 {
    flex: 1;
}

.uui-layout72_item-2 {
    width: 100%;
    background-color: #f9fafb;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1.5rem;
    display: flex;
}

.uui-layout72_item-2.quote-wrapper {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 22px 44px rgba(13, 75, 112, .1);
}

.uui-layout72_component-2 {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    align-items: stretch;
    justify-items: start;
    display: grid;
}

.green-wrapper {
    height: 250px;
    background-color: #56bd88;
    border-radius: 20px;
    padding-top: 20px;
    padding-left: 40px;
    padding-right: 40px;
}

.uui-testimonial06_slide-nav {
    height: 1.75rem;
    font-size: .5rem;
}

.uui-testimonial06_arrow {
    width: 3.5rem;
    height: 3.5rem;
    color: #667085;
    background-color: #fff;
    border: 1px solid #eaecf0;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    transition: all .2s;
    display: flex;
}

.uui-testimonial06_arrow:hover {
    background-color: #f9fafb;
}

.uui-testimonial06_logo {
    max-height: 3rem;
}

.uui-testimonial06_divider {
    width: 1px;
    background-color: #eaecf0;
    align-self: stretch;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
}

.uui-text-size-medium-5 {
    color: #475467;
    letter-spacing: normal;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1rem;
    line-height: 1.5;
}

.uui-heading-tiny {
    color: #667085;
    letter-spacing: normal;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Space Grotesk, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
}

.uui-heading-tiny._4-up {
    color: #538fb2;
    text-align: center;
    font-family: Rubik variablefont wght, sans-serif;
    font-weight: 300;
}

.uui-testimonial06_client-image {
    width: 3.5rem;
    height: 3.5rem;
    min-height: 3.5rem;
    min-width: 3.5rem;
    object-fit: cover;
    border-radius: 100%;
}

.uui-testimonial06_client-image-wrapper {
    margin-right: 1rem;
}

.uui-testimonial06_client {
    text-align: left;
    align-items: center;
    display: flex;
    position: relative;
}

.uui-testimonial06_rating-icon {
    width: 1.25rem;
    height: 1.25rem;
    color: #fec84b;
    justify-content: center;
    align-items: center;
    margin-left: .125rem;
    margin-right: .125rem;
    display: flex;
}

.uui-testimonial06_rating-wrapper {
    align-items: center;
    display: flex;
}

.uui-testimonial06_content {
    max-width: 64rem;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    text-align: center;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.uui-testimonial06_slide {
    padding-left: 4rem;
    padding-right: 4rem;
}

.uui-testimonial06_component {
    height: auto;
    background-color: rgba(0, 0, 0, 0);
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 4rem;
}

.gfe-video-wrapper {
    border: 8px solid #fff;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 22px 44px rgba(13, 75, 112, .1);
}

.uui-text-size-medium-6 {
    color: #315074;
    letter-spacing: normal;
    padding: 0;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 25px;
}

.uui-text-size-medium-6.centered {
    color: #475467;
    text-align: center;
}

.uui-space-xxsmall-4 {
    width: 100%;
    min-height: .5rem;
}

.uui-heading-xxsmall-5 {
    color: #56bd88;
    letter-spacing: normal;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
}

.uui-heading-xxsmall-5.blue {
    color: #538fb2;
}

.uui-space-xxlarge-3 {
    width: 100%;
    min-height: 5rem;
}

.uui-icon-1x1-xsmall-3 {
    width: 1.5rem;
    height: 1.5rem;
}

.icon-featured-square-large-3 {
    width: 3rem;
    height: 3rem;
    color: #fff;
    background-color: #9e77ed;
    border-radius: .625rem;
    justify-content: center;
    align-items: center;
    display: flex;
}

.uui-layout83_item-2 {
    width: 100%;
    background-color: #f9fafb;
    border-radius: 10px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1.5rem;
    display: flex;
    box-shadow: 0 22px 44px rgba(13, 75, 112, .1);
}

.uui-layout83_item-2.grid-offset-1 {
    margin-top: 100px;
}

.uui-layout83_item-2.grid-offset-1.no-top-padding {
    margin-top: 0;
}

.uui-layout83_item-2.grid-offset-3 {
    margin-top: 30px;
}

.uui-layout83_item-2.grid-offset-4 {
    margin-top: -60px;
}

.uui-layout83_item-2.about-grid-offset-1 {
    margin-top: 170px;
}

.uui-layout83_item-2.about-grid-offset-4 {
    margin-top: -110px;
}

.uui-layout83_list-2 {
    width: 100%;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    grid-template-rows: auto;
}

.uui-button-4 {
    grid-column-gap: .5rem;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #7f56d9;
    border: 1px solid #7f56d9;
    border-radius: .5rem;
    justify-content: center;
    align-items: center;
    padding: .625rem 1.125rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    text-decoration: none;
    transition: all .3s;
    display: flex;
    box-shadow: 0 1px 2px rgba(16, 24, 40, .05);
}

.uui-button-4:hover {
    background-color: #6941c6;
    border-color: #6941c6;
}

.uui-button-4:focus {
    background-color: #7f56d9;
    box-shadow: 0 1px 2px rgba(16, 24, 40, .05), 0 0 0 4px #f4ebff;
}

.uui-button-4.is-button-small {
    grid-column-gap: .5rem;
    padding: .5rem 1rem;
}

.uui-button-secondary-gray-3 {
    grid-column-gap: .5rem;
    color: #344054;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    border-radius: .5rem;
    justify-content: center;
    align-items: center;
    padding: .625rem 1.125rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    text-decoration: none;
    transition: all .3s;
    display: flex;
    box-shadow: 0 1px 2px rgba(16, 24, 40, .05);
}

.uui-button-secondary-gray-3:hover {
    color: #1d2939;
    background-color: #f9fafb;
}

.uui-button-secondary-gray-3:focus {
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(16, 24, 40, .05), 0 0 0 4px #f2f4f7;
}

.uui-button-secondary-gray-3.is-button-small {
    grid-column-gap: .5rem;
    padding: .5rem 1rem;
    font-size: 1rem;
}

.uui-button-secondary-gray-3.is-button-small.icon-only {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
}

.uui-button-secondary-gray-3.icon-only {
    width: 2.75rem;
    height: 2.75rem;
    justify-content: center;
    align-items: center;
    padding: 0;
    display: flex;
}

.uui-button-secondary-gray-3.show-tablet {
    display: none;
}

.uui-button-row-4 {
    grid-column-gap: .75rem;
    grid-row-gap: .75rem;
    flex-wrap: wrap;
    align-items: center;
    display: flex;
}

.uui-space-medium-4 {
    width: 100%;
    min-height: 2rem;
}

.uui-text-size-large-4 {
    color: #315074;
    letter-spacing: normal;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 27px;
}

.uui-text-size-large-4.centered {
    text-align: center;
}

.uui-space-xsmall-4 {
    width: 100%;
    min-height: 1rem;
}

.uui-heading-medium-5 {
    color: #315074;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Space Grotesk, sans-serif;
    font-size: 34px;
    font-weight: 400;
    line-height: 43px;
}

.uui-heading-subheading-4 {
    color: #315074;
    margin-bottom: .75rem;
    font-family: Space Grotesk, sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.5;
}

.uui-layout83_content-2 {
    flex-direction: column;
    align-items: center;
}

.uui-layout83_component-2 {
    grid-column-gap: 4rem;
    grid-row-gap: 4rem;
    grid-template-rows: auto;
    grid-template-columns: .5fr 1fr;
    grid-auto-columns: 1fr;
    align-items: start;
    justify-items: start;
    display: grid;
}

.uui-padding-vertical-xhuge-4 {
    padding-top: 7rem;
    padding-bottom: 7rem;
}

.uui-container-large-4 {
    width: 100%;
    max-width: 70rem;
    margin-left: auto;
    margin-right: auto;
}

.uui-page-padding-4 {
    padding-left: 2rem;
    padding-right: 2rem;
}

.uui-page-padding-4.padding-bottom {
    margin-bottom: 100px;
    padding-left: 0;
    padding-right: 0;
}

.uui-section_layout83-2._4-up {
    margin-top: 30px;
    margin-bottom: 100px;
}

.top-offset-icon {
    margin-top: -60px;
}

.rich-text-block {
    color: #fff;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 27px;
}

.uui-button-icon-3 {
    width: 1.25rem;
    height: 1.25rem;
    min-height: 1.25rem;
    min-width: 1.25rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
}

.uui-button-icon-3.text-color-gray400 {
    color: #98a2b3;
}

.uui-button-link-3 {
    grid-column-gap: .5rem;
    color: #56bd88;
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0);
    justify-content: center;
    align-items: center;
    padding: 0;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-decoration: none;
    transition: all .3s;
    display: flex;
}

.uui-button-link-3:hover {
    color: #56bd88;
}

.uui-space-small-4 {
    width: 100%;
    min-height: 1.5rem;
}

.uui-text-size-medium-7 {
    color: #315074;
    letter-spacing: normal;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 27px;
}

.uui-heading-xsmall {
    color: #315074;
    letter-spacing: 0;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Space Grotesk, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.4;
}

.uui-blog04_title-link {
    margin-bottom: .5rem;
    text-decoration: none;
    display: block;
}

.uui-blog04_title-link.featured-article {
    margin-bottom: .75rem;
}

.uui-blog04_read-time {
    align-items: center;
    padding-left: .5rem;
    padding-right: .5rem;
    display: flex;
}

.uui-blog04_category-link {
    color: #6941c6;
    background-color: #fff;
    border-radius: 1rem;
    align-items: center;
    padding: .125rem .5rem;
    font-size: .75rem;
    font-weight: 500;
    text-decoration: none;
    display: flex;
}

.uui-blog04_meta-wrapper {
    color: #56bd88;
    mix-blend-mode: multiply;
    background-color: rgba(86, 189, 136, .1);
    border-radius: 10rem;
    align-items: center;
    margin-bottom: 1rem;
    padding: .25rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: .75rem;
    font-weight: 500;
    display: flex;
}

.uui-blog04_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

.uui-blog04_image-wrapper {
    width: 100%;
    padding-top: 66%;
    position: relative;
    overflow: hidden;
}

.uui-blog04_image-link {
    width: 100%;
    margin-bottom: 2rem;
    transition: all .3s;
}

.uui-blog04_image-link:hover {
    border-radius: 20px;
    box-shadow: 0 24px 48px -12px rgba(16, 24, 40, .18);
}

.uui-blog04_image-link.is-featured {
    margin-bottom: 0;
}

.uui-blog04_item {
    border-bottom: 1px #d0d5dd;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 20px;
    display: flex;
}

.uui-blog04_list {
    grid-column-gap: 2rem;
    grid-row-gap: 4rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
}

.uui-blog04_menu-link {
    color: #667085;
    white-space: nowrap;
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    flex: none;
    margin-right: 1rem;
    padding: .5rem .25rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-weight: 600;
    text-decoration: none;
    transition: all .3s;
}

.uui-blog04_menu-link:hover,
.uui-blog04_menu-link.w--current {
    color: #6941c6;
    border-bottom-color: #6941c6;
}

.uui-blog04_category-menu {
    width: 100%;
    align-items: center;
    margin-bottom: 4rem;
    display: flex;
}

.uui-blog04_category-menu.no-scrollbar {
    box-shadow: inset 0 -1px #eaecf0;
}

.uui-heading-small {
    color: #315074;
    letter-spacing: 0;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Space Grotesk, sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
}

.uui-blog04_featured-item-content {
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 0 0 3rem;
    display: flex;
}

.uui-blog04_featured-item {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    align-items: center;
    margin-bottom: 4rem;
    display: grid;
}

.uui-blog04_component {
    margin-top: 6rem;
}

.uui-text-size-large-5 {
    color: #315074;
    letter-spacing: normal;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
}

.uui-heading-large {
    color: #315074;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Space Grotesk, sans-serif;
    font-size: 60px;
    font-weight: 400;
    line-height: 1.2;
}

.uui-max-width-large-3 {
    width: 100%;
    max-width: 48rem;
}

.text-block {
    font-family: Rubik variablefont wght, sans-serif;
    font-weight: 300;
}

.blog-feature {
    margin-top: 60px;
}

.collection-list {
    grid-column-gap: 34px;
    grid-row-gap: 34px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
}

.text-block-2 {
    font-family: Rubik variablefont wght, sans-serif;
    font-weight: 300;
}

.uui-text-style-link-2 {
    color: #475467;
    text-decoration: underline;
    transition: color .3s;
}

.uui-text-style-link-2:hover {
    color: #344054;
}

.uui-text-size-small-2 {
    color: #315074;
    letter-spacing: normal;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: .875rem;
    font-weight: 300;
    line-height: 1.5;
}

.uui-form_input-2 {
    height: auto;
    min-height: 2.75rem;
    color: #101828;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    border-radius: .5rem;
    margin-bottom: 0;
    padding: .5rem .875rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    transition: all .3s;
    box-shadow: 0 1px 2px rgba(16, 24, 40, .05);
}

.uui-form_input-2:focus {
    color: #101828;
    border-color: #d6bbfb;
    box-shadow: 0 1px 2px rgba(16, 24, 40, .05), 0 0 0 4px #f4ebff;
}

.uui-form_input-2::-ms-input-placeholder {
    color: #667085;
}

.uui-form_input-2::placeholder {
    color: #667085;
}

.uui-blogpost04_form-wrapper {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    margin-bottom: 1rem;
    display: grid;
}

.uui-blogpost04_form {
    margin-bottom: 0;
}

.uui-text-size-medium-8 {
    color: #315074;
    letter-spacing: normal;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 27px;
}

.uui-heading-xsmall-2 {
    color: #101828;
    letter-spacing: 0;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.4;
}

.icon-featured-large {
    width: 3rem;
    height: 3rem;
    color: #7f56d9;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.uui-blogpost04_newsletter {
    background-color: #fff;
    border-radius: 1rem;
    padding: 2rem 2rem 2.5rem;
    position: -webkit-sticky;
    position: sticky;
    top: 5rem;
    box-shadow: 0 22px 44px rgba(13, 75, 112, .1);
}

.uui-text-rich-text {
    color: #315074;
    font-family: Rubik variablefont wght, sans-serif;
}

.uui-text-rich-text h1 {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
}

.uui-text-rich-text h2 {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}

.uui-text-rich-text h3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.uui-text-rich-text h4 {
    margin-top: 1.5rem;
    margin-bottom: 1.25rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
}

.uui-text-rich-text h5,
.uui-text-rich-text h6 {
    margin-top: 1.25rem;
    margin-bottom: 1rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
}

.uui-text-rich-text p {
    margin-bottom: 1rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
}

.uui-text-rich-text blockquote {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-style: italic;
}

.uui-text-rich-text a {
    text-decoration: underline;
}

.uui-text-rich-text figcaption {
    color: #667085;
    text-align: left;
    margin-top: 1rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: .875rem;
}

.uui-blogpost04_content {
    margin-bottom: 4rem;
}

.uui-blogpost04_content-wrapper {
    grid-column-gap: 5rem;
    grid-row-gap: 4rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr .5fr;
    grid-auto-columns: 1fr;
    align-items: start;
    display: grid;
}

.uui-blogpost04_share {
    grid-column-gap: .5rem;
    grid-row-gap: .5rem;
    grid-template-rows: auto;
    grid-template-columns: max-content;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    justify-items: start;
    display: grid;
}

.uui-blogpost04_author-text {
    color: #475467;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1rem;
    font-weight: 500;
}

.uui-blogpost04_author-subheading {
    color: #7f56d9;
    letter-spacing: normal;
    margin-bottom: .75rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: .875rem;
    font-weight: 600;
}

.uui-blogpost04_author-content {
    margin-right: 4rem;
}

.uui-blogpost04_author-wrapper {
    align-items: center;
    display: flex;
}

.uui-blogpost04_content-top {
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 4rem;
    display: flex;
}

.uui-blogpost04_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

.uui-blogpost04_image-wrapper {
    width: 100%;
    margin: 4rem auto 2rem;
    padding-top: 40%;
    position: relative;
    overflow: hidden;
}

.uui-text-size-xlarge-2 {
    color: #315074;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.5;
}

.uui-heading-large-2 {
    color: #315074;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Space Grotesk, sans-serif;
    font-size: 3rem;
    font-weight: 400;
    line-height: 1.2;
}

.uui-blogpost04_title-wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.uui-blogpost04_read-time {
    align-items: center;
    padding-left: .5rem;
    padding-right: .5rem;
    display: flex;
}

.uui-blogpost04_category-link {
    color: #6941c6;
    background-color: #fff;
    border-radius: 1rem;
    align-items: center;
    padding: .125rem .5rem;
    font-size: .75rem;
    font-weight: 500;
    transition: all .3s;
    display: flex;
}

.uui-blogpost04_category-link:hover {
    color: #53389e;
}

.uui-blogpost04_meta-wrapper {
    color: #56bd88;
    mix-blend-mode: multiply;
    background-color: rgba(86, 189, 136, .1);
    border-radius: 10rem;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 1rem;
    padding: .25rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: .75rem;
    font-weight: 500;
    display: flex;
}

.uui-button-link-4 {
    grid-column-gap: .5rem;
    color: #56bd88;
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0);
    justify-content: center;
    align-items: center;
    padding: 0;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5;
    text-decoration: none;
    transition: all .3s;
    display: flex;
}

.uui-button-link-4:hover {
    color: #56bd88;
}

.uui-blogpost04_header {
    flex-direction: column;
    align-items: flex-start;
    display: flex;
}

.text-block-3 {
    color: #56bd88;
}

.bold-text {
    color: #315074;
    font-family: Space Grotesk, sans-serif;
    font-weight: 400;
}

.error-text {
    color: #e23939;
}

.error-message {
    margin-top: 1.5rem;
    padding: .875rem 1rem;
}

.success-text {
    font-weight: 600;
}

.success-message {
    background-color: #f4f4f4;
    padding: 1.5rem;
}

.uui-text-size-small-3 {
    color: #315074;
    letter-spacing: normal;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5;
}

.uui-signup-form_wrapper-2 {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr max-content;
    grid-auto-columns: 1fr;
    margin-bottom: .375rem;
    display: grid;
}

.uui-cta06_form {
    max-width: 30rem;
    margin-bottom: 0;
}

.uui-text-size-large-6 {
    color: #315074;
    letter-spacing: normal;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
}

.uui-heading-medium-6 {
    color: #315074;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Space Grotesk, sans-serif;
    font-size: 2.25rem;
    font-weight: 400;
    line-height: 1.3;
}

.uui-cta06_content {
    flex: 1;
}

.uui-cta06_component {
    grid-column-gap: 4rem;
    grid-row-gap: 2rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr .75fr;
    grid-auto-columns: 1fr;
    justify-content: space-between;
    align-items: start;
    display: grid;
}

.uui-cta06_component.newsletter-wrapper {
    background-color: #fff;
    border-radius: 20px;
    margin-bottom: 0;
    padding: 40px;
    box-shadow: 0 22px 44px rgba(13, 74, 112, .1);
}

.uui-navbar08_menu-button-2 {
    padding: 0;
}

.uui-button-tertiary-gray-2 {
    grid-column-gap: .5rem;
    color: #475467;
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: .5rem;
    justify-content: center;
    align-items: center;
    padding: .625rem 1.125rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    text-decoration: none;
    transition: all .3s;
    display: flex;
}

.uui-button-tertiary-gray-2:hover {
    color: #344054;
    background-color: #f9fafb;
}

.show-tablet-2 {
    display: none;
}

.uui-navbar08_button-wrapper-2 {
    grid-column-gap: .75rem;
    grid-row-gap: .75rem;
    align-items: center;
    display: flex;
}

.uui-navbar08_menu-right-2 {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    align-items: center;
    display: flex;
}

.uui-navbar08_link-2 {
    color: #475467;
    align-items: center;
    padding: .75rem 1rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1rem;
    font-weight: 600;
    transition: all .3s;
}

.uui-navbar08_link-2:hover {
    color: #344054;
}

.uui-navbar08_link-2.w--current {
    color: #000;
}

.uui-text-size-small-4 {
    color: #475467;
    letter-spacing: normal;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: .875rem;
    line-height: 1.5;
}

.uui-navbar08_item-heading-2 {
    color: #101828;
    margin-bottom: .25rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-weight: 600;
}

.uui-navbar08_item-heading-2.margin-bottom-0 {
    margin-bottom: 0;
}

.uui-navbar08_item-right-2 {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    display: flex;
}

.uui-navbar08_icon-wrapper-2 {
    color: #7f56d9;
    flex: none;
}

.uui-navbar08_dropdown-link-2 {
    grid-column-gap: 1rem;
    grid-row-gap: 0px;
    border-radius: .5rem;
    grid-template-rows: auto;
    grid-template-columns: max-content 1fr;
    grid-auto-columns: 1fr;
    align-items: flex-start;
    padding: .75rem;
    text-decoration: none;
    transition: all .3s;
    display: grid;
}

.uui-navbar08_dropdown-link-2:hover {
    background-color: #f9fafb;
}

.uui-badge-small-success-2 {
    grid-column-gap: .25rem;
    color: #027a48;
    white-space: nowrap;
    mix-blend-mode: multiply;
    background-color: #ecfdf3;
    border-radius: 10rem;
    align-items: center;
    padding: .125rem .5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: .75rem;
    font-weight: 500;
    display: flex;
}

.uui-navbar08_text-wrapper-2 {
    grid-column-gap: .5rem;
    grid-row-gap: 0px;
    align-items: center;
    margin-bottom: .25rem;
    display: flex;
}

.uui-navbar08_heading-2 {
    color: #7f56d9;
    letter-spacing: normal;
    margin-top: 0;
    margin-bottom: .25rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: .875rem;
    font-weight: 600;
}

.uui-navbar08_dropdown-link-list-2 {
    grid-column-gap: 0px;
    grid-row-gap: .5rem;
    grid-template-rows: max-content;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    grid-auto-columns: 1fr;
    display: grid;
}

.uui-navbar08_dropdown-content-2 {
    grid-column-gap: 2rem;
    grid-row-gap: 0px;
    flex: 1;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
}

.uui-navbar08_dropdown-list-2 {
    box-shadow: 0 12px 16px -4px rgba(0, 0, 0, .08), 0 4px 6px -2px rgba(16, 24, 40, .03);
}

.uui-navbar08_dropdown-list-2.w--open {
    width: 40rem;
    background-color: #fff;
    border: 1px solid #eaecf0;
    border-radius: .75rem;
    padding: 1.25rem;
    left: -100%;
}

.uui-dropdown-icon-2 {
    width: 1.25rem;
    height: 1.25rem;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    display: flex;
    position: absolute;
    top: auto;
    bottom: auto;
    left: auto;
    right: 0%;
}

.uui-navbar08_dropdown-toggle-2 {
    color: #475467;
    align-items: center;
    padding: .75rem 2.5rem .75rem 1rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1rem;
    font-weight: 600;
    transition: all .3s;
    display: flex;
}

.uui-navbar08_dropdown-toggle-2:hover {
    color: #344054;
}

.uui-navbar08_menu-left-2 {
    margin-left: auto;
    margin-right: auto;
}

.uui-navbar08_menu-2 {
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin-left: 1.5rem;
    display: flex;
    position: static;
}

.uui-logo_image-2 {
    width: auto;
    height: 100%;
    flex: none;
    display: none;
}

.uui-logo_logotype-2 {
    width: auto;
    height: 100%;
    flex: none;
}

.uui-logo_logomark-dot-2 {
    z-index: 1;
    width: 50%;
    height: 50%;
    background-image: linear-gradient(26.5deg, #6941c6, #53389e);
    border-radius: 50%;
    position: relative;
}

.uui-logo_logomark-blur-2 {
    z-index: 2;
    width: 100%;
    height: 50%;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background-color: rgba(255, 255, 255, .2);
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
    position: absolute;
    top: 50%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    overflow: hidden;
}

.uui-styleguide_logomark-bg-2 {
    width: 2rem;
    height: 2rem;
    border-radius: 8px;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

.uui-logo_logomark-2 {
    width: 2rem;
    height: 2rem;
    border: .1px solid #dae0e8;
    border-radius: .5rem;
    flex: none;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    overflow: hidden;
    box-shadow: 0 1px 1px rgba(16, 24, 40, .06), 0 1px 2px rgba(16, 24, 40, .1);
}

.uui-logo_component-2 {
    width: auto;
    height: 2rem;
    flex-wrap: nowrap;
    display: flex;
}

.uui-navbar08_logo-link-2 {
    padding-left: 0;
}

.uui-navbar08_container-2 {
    width: 100%;
    height: 100%;
    max-width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.uui-navbar08_component-2 {
    min-height: 5rem;
    background-color: #fff;
    border: 1px #000;
    border-radius: 100px;
    align-items: center;
    margin-top: 40px;
    margin-left: 2rem;
    margin-right: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
    box-shadow: 0 1px 11px rgba(13, 75, 112, .1);
}

.uui-text-style-link-3 {
    color: #475467;
    text-decoration: underline;
    transition: color .3s;
}

.uui-text-style-link-3:hover {
    color: #344054;
}

.uui-text-size-small-5 {
    color: #475467;
    letter-spacing: normal;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: .875rem;
    line-height: 1.5;
}

.uui-text-size-small-5.text-align-left {
    text-align: left;
}

.uui-button-5 {
    grid-column-gap: .5rem;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #7f56d9;
    border: 1px solid #7f56d9;
    border-radius: .5rem;
    justify-content: center;
    align-items: center;
    padding: .625rem 1.125rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    text-decoration: none;
    transition: all .3s;
    display: flex;
    box-shadow: 0 1px 2px rgba(16, 24, 40, .05);
}

.uui-button-5:hover {
    background-color: #6941c6;
    border-color: #6941c6;
}

.uui-button-5:focus {
    background-color: #7f56d9;
    box-shadow: 0 1px 2px rgba(16, 24, 40, .05), 0 0 0 4px #f4ebff;
}

.uui-form_input-3 {
    height: auto;
    min-height: 2.75rem;
    color: #101828;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    border-radius: .5rem;
    margin-bottom: 0;
    padding: .5rem .875rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    transition: all .3s;
    box-shadow: 0 1px 2px rgba(16, 24, 40, .05);
}

.uui-form_input-3:focus {
    color: #101828;
    border-color: #d6bbfb;
    box-shadow: 0 1px 2px rgba(16, 24, 40, .05), 0 0 0 4px #f4ebff;
}

.uui-form_input-3::-ms-input-placeholder {
    color: #667085;
}

.uui-form_input-3::placeholder {
    color: #667085;
}

.uui-signup-form_wrapper-3 {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr max-content;
    grid-auto-columns: 1fr;
    margin-bottom: .375rem;
    display: grid;
}

.uui-signup-form_wrapper-3.hidden {
    display: none;
}

.uui-header09_form {
    max-width: 30rem;
    text-align: left;
    margin: 1rem auto 0;
}

.uui-text-size-xlarge-3 {
    color: #315074;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
}

.uui-space-small-5 {
    width: 100%;
    min-height: 1.5rem;
}

.uui-heading-large-3 {
    color: #315074;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Space Grotesk, sans-serif;
    font-size: 3rem;
    font-weight: 400;
    line-height: 1.2;
}

.uui-heading-subheading-5 {
    color: #315074;
    background-color: rgba(49, 80, 116, .1);
    border-radius: 20px;
    margin-bottom: .75rem;
    padding: 2px 20px;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.5;
    display: inline-block;
}

.uui-text-align-center-3 {
    text-align: center;
}

.uui-padding-vertical-xhuge-5 {
    padding-top: 7rem;
    padding-bottom: 7rem;
}

.uui-container-small-2 {
    width: 100%;
    max-width: 48rem;
    margin-left: auto;
    margin-right: auto;
}

.uui-page-padding-5 {
    padding-left: 2rem;
    padding-right: 2rem;
}

.uui-section_header09 {
    background-image: url("https://uploads-ssl.webflow.com/6405f18ff0cf3cb688b7fba9/641212ddd61f2df365c52dc8_Group%2060.svg");
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.image-2 {
    width: 100%;
}

.error-text-2 {
    color: #e23939;
}

.error-message-2 {
    margin-top: 1.5rem;
    padding: .875rem 1rem;
}

.success-text-2 {
    font-weight: 600;
}

.success-message-2 {
    background-color: #f4f4f4;
    padding: 1.5rem;
}

.uui-cta10_form {
    max-width: 30rem;
    margin: 2.5rem auto 0;
}

.uui-text-size-large-7 {
    color: #315074;
    letter-spacing: normal;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
}

.uui-space-xsmall-5 {
    width: 100%;
    min-height: 1rem;
}

.uui-heading-medium-7 {
    color: #315074;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Space Grotesk, sans-serif;
    font-size: 34px;
    font-weight: 400;
    line-height: 1.3;
}

.uui-section_cta10 {
    background-color: #fff;
    position: relative;
}

.press-touts {
    max-width: 100%;
    overflow: hidden;
}

.press-stack {
    margin-top: 40px;
}

.press-line-item-wrapper {
    background-color: #fff;
    border-radius: 20px;
    margin-bottom: 40px;
    padding: 20px 40px;
    box-shadow: 0 22px 44px rgba(13, 75, 112, .1);
}

.grid {
    grid-column-gap: 48px;
    grid-row-gap: 48px;
    grid-template-rows: auto;
    grid-template-columns: .25fr 1fr;
}

.press-logo-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
}

.image-3 {
    max-width: 80%;
}

.press-item-title {
    color: #315074;
    text-align: left;
    margin-bottom: 20px;
    font-family: Space Grotesk, sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 31px;
}

.press-read-now-link {
    color: #56bd88;
    text-align: left;
    font-family: Rubik variablefont wght, sans-serif;
    font-weight: 300;
    text-decoration: none;
    display: block;
}

.uui-text-size-medium-9 {
    color: #475467;
    letter-spacing: normal;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1rem;
    line-height: 1.5;
}

.uui-text-divider {
    color: #475467;
    margin-left: .25rem;
    margin-right: .25rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1rem;
    line-height: 1.5;
}

.uui-blogpost01_date-wrapper {
    align-items: center;
    display: flex;
}

.uui-blogpost01_author-heading {
    color: #101828;
    letter-spacing: normal;
    margin-top: 0;
    margin-bottom: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1.125rem;
    font-weight: 600;
}

.uui-blogpost01_author-image {
    width: 3.5rem;
    height: 3.5rem;
    min-height: 3.5rem;
    min-width: 3.5rem;
    object-fit: cover;
    border-radius: 100%;
}

.uui-blogpost01_author-image-wrapper {
    margin-right: 1rem;
}

.uui-blogpost01_author-wrapper {
    align-items: center;
    display: flex;
}

.uui-blogpost01_divider {
    width: 100%;
    height: 1px;
    max-width: 48rem;
    background-color: #eaecf0;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
}

.uui-badge {
    grid-column-gap: .375rem;
    color: #344054;
    white-space: nowrap;
    background-color: #f2f4f7;
    border-radius: 10rem;
    align-items: center;
    padding: .125rem .625rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: .875rem;
    font-weight: 500;
    line-height: 1.5;
    text-decoration: none;
    display: flex;
}

.uui-badge.is-pink {
    color: #c11574;
    background-color: #fdf2fa;
}

.uui-badge.is-indigo {
    color: #3538cd;
    background-color: #eef4ff;
}

.uui-badge.is-primary {
    color: #6941c6;
    background-color: #f9f5ff;
}

.uui-blogpost01_tag-list {
    grid-column-gap: .5rem;
    flex-wrap: wrap;
    justify-content: flex-end;
    display: flex;
}

.uui-blogpost01_tag-list-wrapper {
    width: 50%;
}

.uui-button-icon-4 {
    width: 1.25rem;
    height: 1.25rem;
    min-height: 1.25rem;
    min-width: 1.25rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
}

.uui-button-icon-4.text-color-gray400 {
    color: #98a2b3;
}

.uui-button-secondary-gray-4 {
    grid-column-gap: .5rem;
    color: #344054;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    border-radius: .5rem;
    justify-content: center;
    align-items: center;
    padding: .625rem 1.125rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    text-decoration: none;
    transition: all .3s;
    display: flex;
    box-shadow: 0 1px 2px rgba(16, 24, 40, .05);
}

.uui-button-secondary-gray-4:hover {
    color: #1d2939;
    background-color: #f9fafb;
}

.uui-button-secondary-gray-4:focus {
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(16, 24, 40, .05), 0 0 0 4px #f2f4f7;
}

.uui-button-secondary-gray-4.is-button-small {
    grid-column-gap: .5rem;
    padding: .5rem 1rem;
    font-size: 1rem;
}

.uui-button-secondary-gray-4.is-button-small.icon-only {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
}

.uui-button-secondary-gray-4.icon-only {
    width: 2.75rem;
    height: 2.75rem;
    justify-content: center;
    align-items: center;
    padding: 0;
    display: flex;
}

.uui-blogpost01_share {
    grid-column-gap: .75rem;
    grid-row-gap: .75rem;
    grid-template-rows: auto;
    grid-template-columns: max-content;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    justify-items: start;
    margin-top: 1rem;
    display: grid;
}

.uui-blogpost01_share-heading {
    color: #101828;
    letter-spacing: normal;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1rem;
    font-weight: 600;
}

.uui-blogpost01_share-wrapper {
    width: 50%;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
}

.uui-blogpost01_content-bottom {
    justify-content: space-between;
    align-items: flex-end;
    display: flex;
}

.uui-text-rich-text-2 {
    color: #475467;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 16px;
    font-style: italic;
    font-weight: 300;
    line-height: 27px;
}

.uui-text-rich-text-2 h1 {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
}

.uui-text-rich-text-2 h2 {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}

.uui-text-rich-text-2 h3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.uui-text-rich-text-2 h4 {
    margin-top: 1.5rem;
    margin-bottom: 1.25rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
}

.uui-text-rich-text-2 h5,
.uui-text-rich-text-2 h6 {
    margin-top: 1.25rem;
    margin-bottom: 1rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
}

.uui-text-rich-text-2 p {
    margin-bottom: 1rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
}

.uui-text-rich-text-2 blockquote {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-style: italic;
}

.uui-text-rich-text-2 a {
    text-decoration: underline;
}

.uui-text-rich-text-2 figcaption {
    color: #667085;
    text-align: left;
    margin-top: 1rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: .875rem;
}

.uui-blogpost01_content {
    margin-bottom: 4rem;
}

.uui-max-width-large-4 {
    width: 100%;
    max-width: 48rem;
}

.uui-max-width-large-4.align-center,
.align-center-2 {
    margin-left: auto;
    margin-right: auto;
}

.uui-blogpost01_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

.uui-blogpost01_image-wrapper {
    width: 100%;
    margin-bottom: 4rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 50%;
    position: relative;
    overflow: hidden;
}

.uui-blogpost01_content-top {
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 3rem;
    display: flex;
}

.uui-heading-large-4 {
    color: #315074;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Space Grotesk, sans-serif;
    font-size: 34px;
    font-weight: 400;
    line-height: 1.2;
}

.uui-breadcrumb-link {
    color: #475467;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-weight: 500;
    text-decoration: none;
    transition: all .3s;
}

.uui-breadcrumb-link:hover {
    color: #344054;
}

.uui-breadcrumb-link.w--current {
    color: #6941c6;
    font-weight: 600;
}

.uui-breadcrumb-link.w--current:hover {
    color: #53389e;
}

.uui-breadcrumb-divider {
    width: 1rem;
    height: 1rem;
    color: #667085;
    justify-content: center;
    align-items: center;
    margin-left: .5rem;
    margin-right: .5rem;
    display: flex;
}

.uui-blogpost01_breadcrumb {
    width: 100%;
    align-items: center;
    margin-bottom: 1.5rem;
    display: flex;
}

.uui-blogpost01_title-wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.uui-padding-vertical-xhuge-6 {
    padding-top: 7rem;
    padding-bottom: 7rem;
}

.uui-container-large-5 {
    width: 100%;
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;
}

.uui-page-padding-6 {
    padding-left: 2rem;
    padding-right: 2rem;
}

.press-page-logo-container {
    width: 140px;
    margin-bottom: 20px;
}

.press-view-article-link {
    color: #56bd88;
    text-align: right;
    margin-top: 20px;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 16px;
    font-weight: 300;
    text-decoration: none;
    display: block;
}

.home-primary-lockup {
    background-image: url("https://uploads-ssl.webflow.com/6405f18ff0cf3cb688b7fba9/6418b63a1db9a55a85e7b442_Group%2066.png");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
}

.home-primary-lockup.employers {
    background-image: url("https://uploads-ssl.webflow.com/6405f18ff0cf3cb688b7fba9/6418b79b3e48d11cb64412e9_Group%2067.png");
}

.home-primary-lockup.desktop {
    background-image: url("https://uploads-ssl.webflow.com/6405f18ff0cf3cb688b7fba9/643067f8e000af3b2cb9911b_Group%2066.png");
}

.grid-2 {
    grid-template-rows: auto;
    grid-template-columns: .5fr 1fr .5fr;
}

.hp-left-container,
.hp-right-container {
    flex-direction: column;
    justify-content: space-between;
    display: flex;
}

.hp-top-lockup {
    margin-top: 100px;
    margin-bottom: 100px;
}

.grid-3 {
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
}

.shadow-box {
    background-color: #fff;
    border-radius: 20px;
    padding: 40px;
    box-shadow: 0 22px 44px rgba(13, 75, 112, .1);
}

.hp-stats {
    margin-bottom: -140px;
}

.hp-love {
    background-color: rgba(83, 143, 178, .3);
    padding-top: 260px;
}

.grid-4 {
    grid-column-gap: 42px;
    grid-row-gap: 42px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 2fr 1fr;
}

.love-bg-wrapper {
    background-image: url("https://uploads-ssl.webflow.com/6405f18ff0cf3cb688b7fba9/64186bfb2fe14369eab593f1_Group%2062.png");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: -40px;
}

.love-bottom {
    background-color: #315074;
    padding-top: 40px;
    padding-bottom: 40px;
}

.pink-box {
    background-color: #ef6f6c;
    border-radius: 20px;
    padding: 40px;
}

.blog-digest-header {
    justify-content: space-between;
    margin-bottom: 40px;
    display: flex;
}

.collection-list-2 {
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
}

.blog-summary-image {
    width: 100%;
    border-radius: 20px;
}

.blog-summary-caption {
    opacity: .99;
    background-color: #fff;
    border-radius: 20px;
    margin-top: -50px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 20px;
}

.blog-digest-date {
    color: #538fb2;
    margin-bottom: 10px;
    font-family: Space Grotesk, sans-serif;
}

.rich-text-block-2 {
    color: #fff;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 27px;
}

.patients-4-up {
    background-color: rgba(83, 143, 178, .3);
    margin-top: 100px;
}

.patient-4up-block {
    background-color: #fff;
    border-radius: 20px;
    padding: 20px 40px;
    box-shadow: 0 22px 44px rgba(13, 75, 112, .1);
}

.patient-4up-block.about {
    padding-bottom: 40px;
}

._4up-offset-image {
    margin-top: -80px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.div-block {
    display: inline-block;
}

.div-block-2 {
    justify-content: space-between;
    align-items: center;
    margin-left: 40px;
    margin-right: 40px;
    display: flex;
}

.div-block-2.wider {
    margin-left: 20px;
    margin-right: 20px;
}

.link {
    color: #56bd88;
}

.grid-5 {
    grid-row-gap: 80px;
}

.patient-4up-container {
    margin-top: 0;
}

.patient-4up-offset {
    margin-top: -230px;
    margin-bottom: -60px;
}

.rich-text-block-3 {
    color: #fff;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 27px;
}

.about-logos {
    background-image: linear-gradient(rgba(49, 80, 116, .1), #fff);
    padding-top: 100px;
}

.about-logo-lockup {
    width: 100%;
    margin-top: 40px;
}

.our-values {
    margin-bottom: -260px;
}

.grid-6 {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.about-values-offset {
    margin-top: -90px;
    margin-bottom: -40px;
}

.founded-by {
    background-image: linear-gradient(rgba(49, 80, 116, .1), #fff);
    padding-top: 280px;
    padding-bottom: 60px;
}

.grid-7 {
    grid-column-gap: 48px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1.25fr;
}

.text-block-4 {
    font-family: Rubik variablefont wght, sans-serif;
    font-weight: 300;
}

.hp-stat-card {
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 22px 44px rgba(13, 75, 112, .1);
}

.stat-card-title {
    color: #538fb2;
    font-family: Rubik variablefont wght, sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.stat-title-larger {
    font-size: 22px;
    font-weight: 400;
}

.image-4 {
    margin-right: 0;
    position: static;
    right: 10px;
}

.header-home-mobile,
.learn-more-vertical {
    display: none;
}

.grid-8 {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid-8.mobile,
.home-primary-lockup-mobile {
    display: none;
}

.grid-9 {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.div-block-3 {
    flex-direction: column;
    display: flex;
}

.div-block-3.footer-contact {
    margin-top: 20px;
}

.footer-contact-link {
    color: #56bd88;
    margin-bottom: 5px;
    font-family: Rubik variablefont wght, sans-serif;
    font-weight: 300;
    text-decoration: none;
}

.logo-strip {
    justify-content: center;
    display: flex;
    overflow: hidden;
}

.logo-strip.img-ticker {
    display: flex;
    overflow: visible;
}

.collection-list-wrapper {
    flex-direction: row;
    flex: 0 auto;
    justify-content: center;
    display: flex;
}

.collection-list-3 {
    justify-content: center;
    display: flex;
}

.logo-wrapper {
    width: 20vw;
    height: 160px;
    border: 1px solid rgba(83, 143, 178, .2);
    justify-content: center;
    align-items: center;
    display: flex;
}

.padded-logo {
    padding: 60px;
    display: block;
}

.collection-item {
    height: 160px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.collection-item-2,
.collection-list-4,
.collection-list-5 {
    display: flex;
}

.uui-text-size-medium-10 {
    color: #475467;
    letter-spacing: normal;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1rem;
    line-height: 1.5;
}

.uui-blogpost03_author-heading-bottom {
    color: #101828;
    letter-spacing: normal;
    margin-top: 0;
    margin-bottom: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1.125rem;
    font-weight: 600;
}

.uui-blogpost03_author-image {
    width: 3.5rem;
    height: 3.5rem;
    min-height: 3.5rem;
    min-width: 3.5rem;
    object-fit: cover;
    border-radius: 100%;
}

.uui-blogpost03_author-image-wrapper {
    margin-right: 1rem;
}

.uui-blogpost03_author-wrapper {
    align-items: center;
    margin-bottom: 2rem;
}

.uui-blogpost03_author-wrapper.content-bottom {
    margin-bottom: 0;
    display: flex;
}

.uui-blogpost03_divider {
    width: 100%;
    height: 1px;
    max-width: 48rem;
    background-color: #eaecf0;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
}

.uui-badge-2 {
    grid-column-gap: .375rem;
    color: #344054;
    white-space: nowrap;
    background-color: #f2f4f7;
    border-radius: 10rem;
    align-items: center;
    padding: .125rem .625rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: .875rem;
    font-weight: 500;
    line-height: 1.5;
    text-decoration: none;
    display: flex;
}

.uui-badge-2.is-pink {
    color: #c11574;
    background-color: #fdf2fa;
}

.uui-badge-2.is-indigo {
    color: #3538cd;
    background-color: #eef4ff;
}

.uui-badge-2.is-primary {
    color: #6941c6;
    background-color: #f9f5ff;
}

.uui-blogpost03_tag-list {
    grid-column-gap: .5rem;
    flex-wrap: wrap;
    justify-content: flex-end;
    display: flex;
}

.uui-blogpost03_tag-list-wrapper {
    width: 50%;
}

.uui-button-icon-5 {
    width: 1.25rem;
    height: 1.25rem;
    min-height: 1.25rem;
    min-width: 1.25rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
}

.uui-button-icon-5.text-color-gray400 {
    color: #98a2b3;
}

.uui-button-secondary-gray-5 {
    grid-column-gap: .5rem;
    color: #344054;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    border-radius: .5rem;
    justify-content: center;
    align-items: center;
    padding: .625rem 1.125rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    text-decoration: none;
    transition: all .3s;
    display: flex;
    box-shadow: 0 1px 2px rgba(16, 24, 40, .05);
}

.uui-button-secondary-gray-5:hover {
    color: #1d2939;
    background-color: #f9fafb;
}

.uui-button-secondary-gray-5:focus {
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(16, 24, 40, .05), 0 0 0 4px #f2f4f7;
}

.uui-button-secondary-gray-5.is-button-small {
    grid-column-gap: .5rem;
    padding: .5rem 1rem;
    font-size: 1rem;
}

.uui-button-secondary-gray-5.is-button-small.icon-only {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
}

.uui-button-secondary-gray-5.icon-only {
    width: 2.75rem;
    height: 2.75rem;
    justify-content: center;
    align-items: center;
    padding: 0;
    display: flex;
}

.uui-blogpost03_share {
    grid-column-gap: .75rem;
    grid-row-gap: .75rem;
    grid-template-rows: auto;
    grid-template-columns: max-content;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    justify-items: start;
    margin-top: 1rem;
    display: grid;
}

.uui-blogpost03_share-heading {
    color: #101828;
    letter-spacing: normal;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1rem;
    font-weight: 600;
}

.uui-blogpost03_share-wrapper {
    width: 50%;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
}

.uui-blogpost03_content-bottom {
    justify-content: space-between;
    align-items: flex-end;
    display: flex;
}

.uui-text-rich-text-3 {
    color: #475467;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
}

.uui-text-rich-text-3 h1 {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
}

.uui-text-rich-text-3 h2 {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}

.uui-text-rich-text-3 h3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.uui-text-rich-text-3 h4 {
    margin-top: 1.5rem;
    margin-bottom: 1.25rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
}

.uui-text-rich-text-3 h5,
.uui-text-rich-text-3 h6 {
    margin-top: 1.25rem;
    margin-bottom: 1rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
}

.uui-text-rich-text-3 p {
    margin-bottom: 1rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
}

.uui-text-rich-text-3 blockquote {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-style: italic;
}

.uui-text-rich-text-3 a {
    text-decoration: underline;
}

.uui-text-rich-text-3 figcaption {
    color: #667085;
    text-align: left;
    margin-top: 1rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: .875rem;
}

.uui-blogpost03_content {
    margin-bottom: 4rem;
}

.uui-max-width-large-5 {
    width: 100%;
    max-width: 48rem;
}

.uui-max-width-large-5.align-center,
.align-center-3 {
    margin-left: auto;
    margin-right: auto;
}

.uui-blogpost03_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

.uui-blogpost03_image-wrapper {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 66%;
    position: relative;
    overflow: hidden;
}

.uui-text-divider-2 {
    color: #475467;
    margin-left: .25rem;
    margin-right: .25rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1rem;
    line-height: 1.5;
}

.uui-blogpost03_date-wrapper {
    margin-bottom: 1rem;
    display: flex;
}

.uui-blogpost03_author-heading {
    color: #101828;
    letter-spacing: normal;
    margin-left: .25rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.5;
}

.uui-text-size-large-8 {
    color: #475467;
    letter-spacing: normal;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
}

.uui-blogpost03_author-text {
    display: flex;
}

.uui-blogpost03_content-top {
    width: 100%;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 3rem;
    display: flex;
}

.uui-heading-large-5 {
    color: #101828;
    margin-top: 0;
    margin-bottom: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.2;
}

.uui-breadcrumb-link-2 {
    color: #475467;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
    font-weight: 500;
    text-decoration: none;
    transition: all .3s;
}

.uui-breadcrumb-link-2:hover {
    color: #344054;
}

.uui-breadcrumb-link-2.w--current {
    color: #6941c6;
    font-weight: 600;
}

.uui-breadcrumb-link-2.w--current:hover {
    color: #53389e;
}

.uui-breadcrumb-divider-2 {
    width: 1rem;
    height: 1rem;
    color: #667085;
    justify-content: center;
    align-items: center;
    margin-left: .5rem;
    margin-right: .5rem;
    display: flex;
}

.uui-blogpost03_breadcrumb {
    width: 100%;
    align-items: center;
    margin-bottom: 2.5rem;
    display: flex;
}

.uui-blogpost03_title-wrapper {
    flex-direction: column;
    display: flex;
}

.uui-blogpost03_header {
    grid-column-gap: 5rem;
    grid-row-gap: 4rem;
    grid-template-rows: auto;
    grid-template-columns: .75fr 1fr;
    grid-auto-columns: 1fr;
    align-items: stretch;
    margin-bottom: 6rem;
    display: grid;
}

.uui-padding-vertical-xhuge-7 {
    padding-top: 7rem;
    padding-bottom: 7rem;
}

.uui-container-large-6 {
    width: 100%;
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;
}

.uui-page-padding-7 {
    padding-left: 2rem;
    padding-right: 2rem;
}

.footer-email-form {
    width: auto;
    margin-top: 20px;
    overflow: hidden;
}

.link-2 {
    color: #315074;
}

.image-5 {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.req-nav-button.mobile {
    display: none;
}

@media screen and (max-width: 991px) {
    .menu-icon_line-bottom {
        width: 24px;
        height: 2px;
        background-color: #344054;
        border-radius: 1rem;
        padding-bottom: 0;
        padding-right: 0;
    }
    .menu-icon_line-middle-inner {
        width: 4px;
        height: 0;
        padding-bottom: 0;
        padding-right: 0;
    }
    .menu-icon_line-middle {
        width: 24px;
        height: 2px;
        background-color: #344054;
        border-radius: 1rem;
        justify-content: center;
        align-items: center;
        margin-top: 6px;
        margin-bottom: 6px;
        padding-bottom: 0;
        padding-right: 0;
        display: flex;
    }
    .menu-icon_line-top {
        width: 24px;
        height: 2px;
        background-color: #344054;
        border-radius: 1rem;
        padding-bottom: 0;
        padding-right: 0;
    }
    .menu-icon_component {
        width: 48px;
        height: 48px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: -.5rem;
        padding-bottom: 0;
        padding-right: 0;
        display: flex;
    }
    .uui-navbar08_menu-button.w--open {
        background-color: rgba(0, 0, 0, 0);
    }
    .hide-tablet {
        display: none;
    }
    .uui-button-secondary-gray.show-tablet,
    .show-tablet {
        display: block;
    }
    .uui-navbar08_button-wrapper {
        flex-direction: column-reverse;
        align-items: stretch;
        display: flex;
    }
    .uui-navbar08_menu-right {
        flex-direction: column-reverse;
        grid-template-rows: auto auto;
        grid-template-columns: 1fr;
        align-items: stretch;
        margin-top: 1.5rem;
        padding-bottom: 7rem;
    }
    .uui-navbar08_link {
        width: 100%;
        color: #101828;
        padding: .75rem 0;
        font-size: 1.125rem;
    }
    .uui-navbar08_link:hover {
        color: #101828;
    }
    .uui-navbar08_dropdown-link {
        padding-left: 0;
        padding-right: 0;
    }
    .uui-navbar08_dropdown-link:hover {
        background-color: rgba(0, 0, 0, 0);
    }
    .uui-navbar08_dropdown-content {
        align-content: start;
        align-items: start;
        padding: 1rem 0;
    }
    .uui-navbar08_dropdown-list {
        position: static;
        overflow: hidden;
    }
    .uui-navbar08_dropdown-list.w--open {
        width: auto;
        box-shadow: none;
        border-style: none;
        border-color: rgba(0, 0, 0, 0);
        padding: 0;
    }
    .uui-dropdown-icon {
        color: #667085;
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 0;
        top: auto;
        bottom: auto;
        left: auto;
        right: 0%;
    }
    .uui-navbar08_dropdown-toggle {
        color: #101828;
        align-items: center;
        padding-top: .75rem;
        padding-bottom: .75rem;
        padding-left: 0;
        font-size: 1.125rem;
        display: flex;
    }
    .uui-navbar08_dropdown-toggle:hover {
        color: #101828;
    }
    .uui-navbar08_menu-dropdown {
        width: 100%;
        font-size: 1.125rem;
    }
    .uui-navbar08_menu-left {
        grid-column-gap: 0px;
        grid-row-gap: .5rem;
        color: #101828;
        flex-direction: column;
        align-items: flex-start;
        display: flex;
    }
    .uui-navbar08_menu {
        height: 100vh;
        -webkit-text-fill-color: inherit;
        background-color: #fff;
        background-clip: border-box;
        border-top: 1px solid #f2f4f7;
        align-items: flex-start;
        margin-left: 0;
        padding: 1.5rem 2rem 5rem;
        position: absolute;
        overflow: auto;
    }
    .uui-heading-xlarge {
        font-size: 3.25rem;
    }
    .uui-heroheader08_component {
        min-height: auto;
        grid-column-gap: 0rem;
        grid-template-columns: 1fr;
        grid-auto-flow: row;
    }
    .uui-padding-vertical-xhuge {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
    .uui-section_heroheader08.desktop {
        display: none;
    }
    .uui-layout20_component-copy {
        grid-row-gap: 1.5rem;
        grid-template-columns: 1fr;
    }
    .uui-space-xxlarge {
        min-height: 4.5rem;
    }
    .uui-layout72_component {
        grid-template-columns: 1fr;
    }
    .uui-layout94_list {
        grid-template-columns: 1fr 1fr;
    }
    .uui-cta09_component {
        padding: 3rem;
    }
    .uui-footer03_top-wrapper {
        grid-row-gap: 4rem;
        grid-template-columns: 1fr;
    }
    .uui-padding-vertical-xlarge {
        padding-top: 3.5rem;
        padding-bottom: 3.5rem;
    }
    .search-form-wrapper {
        margin-left: 0;
        margin-right: auto;
    }
    .uui-padding-vertical-xhuge-2 {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
    .uui-section_faq01.padded-top {
        margin-top: 400px;
    }
    .uui-layout49_image {
        width: 35rem;
        min-width: 35rem;
        right: 2rem;
    }
    .uui-layout49_component {
        min-height: auto;
        grid-column-gap: 3rem;
        grid-auto-flow: row;
    }
    .uui-padding-vertical-xhuge-3 {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
    .uui-section_layout49 {
        margin-top: 400px;
    }
    .uui-section_layout49.for-patients {
        margin-top: 500px;
    }
    .uui-space-xxlarge-2 {
        min-height: 4.5rem;
    }
    .uui-layout83_component {
        grid-template-columns: 1fr;
    }
    .uui-section_layout83 {
        margin-top: 400px;
    }
    .uui-layout72_component-2 {
        grid-template-columns: 1fr;
    }
    .uui-space-xxlarge-3 {
        min-height: 4.5rem;
    }
    .uui-button-secondary-gray-3.show-tablet {
        display: block;
    }
    .uui-layout83_component-2 {
        grid-template-columns: 1fr;
    }
    .uui-padding-vertical-xhuge-4 {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
    .uui-blog04_image-wrapper.is-featured {
        padding-top: 75%;
    }
    .uui-blog04_list {
        grid-template-columns: 1fr 1fr;
    }
    .uui-blog04_featured-item-content {
        margin-top: 20px;
    }
    .uui-blog04_featured-item {
        display: inline-block;
    }
    .uui-heading-large {
        font-size: 2.75rem;
    }
    .collection-list {
        display: inline-block;
    }
    .uui-blogpost04_newsletter {
        position: static;
    }
    .uui-blogpost04_content {
        margin-bottom: 3rem;
    }
    .uui-blogpost04_content-wrapper {
        grid-row-gap: 3rem;
        grid-template-columns: 1fr;
    }
    .uui-blogpost04_image-wrapper {
        margin-bottom: 3rem;
        padding-top: 56.25%;
    }
    .uui-heading-large-2 {
        font-size: 2.75rem;
    }
    .uui-cta06_content {
        margin-right: 0;
    }
    .uui-cta06_component {
        grid-template-columns: 1fr;
    }
    .menu-icon_line-bottom-2 {
        width: 24px;
        height: 2px;
        background-color: #344054;
        border-radius: 1rem;
        padding-bottom: 0;
        padding-right: 0;
    }
    .menu-icon_line-middle-inner-2 {
        width: 4px;
        height: 0;
        padding-bottom: 0;
        padding-right: 0;
    }
    .menu-icon_line-middle-2 {
        width: 24px;
        height: 2px;
        background-color: #344054;
        border-radius: 1rem;
        justify-content: center;
        align-items: center;
        margin-top: 6px;
        margin-bottom: 6px;
        padding-bottom: 0;
        padding-right: 0;
        display: flex;
    }
    .menu-icon_line-top-2 {
        width: 24px;
        height: 2px;
        background-color: #344054;
        border-radius: 1rem;
        padding-bottom: 0;
        padding-right: 0;
    }
    .menu-icon_component-2 {
        width: 48px;
        height: 48px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: -.5rem;
        padding-bottom: 0;
        padding-right: 0;
        display: flex;
    }
    .uui-navbar08_menu-button-2.w--open {
        background-color: rgba(0, 0, 0, 0);
    }
    .hide-tablet-2 {
        display: none;
    }
    .show-tablet-2 {
        display: block;
    }
    .uui-navbar08_button-wrapper-2 {
        flex-direction: column-reverse;
        align-items: stretch;
        display: flex;
    }
    .uui-navbar08_menu-right-2 {
        flex-direction: column-reverse;
        grid-template-rows: auto auto;
        grid-template-columns: 1fr;
        align-items: stretch;
        margin-top: 1.5rem;
        padding-bottom: 7rem;
    }
    .uui-navbar08_link-2 {
        width: 100%;
        color: #101828;
        padding: .75rem 0;
        font-size: 1.125rem;
    }
    .uui-navbar08_link-2:hover {
        color: #101828;
    }
    .uui-navbar08_dropdown-link-2 {
        padding-left: 0;
        padding-right: 0;
    }
    .uui-navbar08_dropdown-link-2:hover {
        background-color: rgba(0, 0, 0, 0);
    }
    .uui-navbar08_dropdown-content-2 {
        align-content: start;
        align-items: start;
        padding: 1rem 0;
    }
    .uui-navbar08_dropdown-list-2 {
        position: static;
        overflow: hidden;
    }
    .uui-navbar08_dropdown-list-2.w--open {
        width: auto;
        box-shadow: none;
        border-style: none;
        border-color: rgba(0, 0, 0, 0);
        padding: 0;
    }
    .uui-dropdown-icon-2 {
        color: #667085;
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 0;
        top: auto;
        bottom: auto;
        left: auto;
        right: 0%;
    }
    .uui-navbar08_dropdown-toggle-2 {
        color: #101828;
        align-items: center;
        padding-top: .75rem;
        padding-bottom: .75rem;
        padding-left: 0;
        font-size: 1.125rem;
        display: flex;
    }
    .uui-navbar08_dropdown-toggle-2:hover {
        color: #101828;
    }
    .uui-navbar08_menu-dropdown-2 {
        width: 100%;
        font-size: 1.125rem;
    }
    .uui-navbar08_menu-left-2 {
        grid-column-gap: 0px;
        grid-row-gap: .5rem;
        color: #101828;
        flex-direction: column;
        align-items: flex-start;
        display: flex;
    }
    .uui-navbar08_menu-2 {
        height: 100vh;
        -webkit-text-fill-color: inherit;
        background-color: #fff;
        background-clip: border-box;
        border-top: 1px solid #f2f4f7;
        align-items: flex-start;
        margin-left: 0;
        padding: 1.5rem 2rem 5rem;
        position: absolute;
        overflow: auto;
    }
    .uui-heading-large-3 {
        font-size: 2.75rem;
    }
    .uui-padding-vertical-xhuge-5 {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
    .uui-blogpost01_content {
        margin-bottom: 3rem;
    }
    .uui-blogpost01_image-wrapper {
        margin-bottom: 3rem;
        padding-top: 56.25%;
    }
    .uui-heading-large-4 {
        font-size: 2.75rem;
    }
    .uui-blogpost01_title-wrapper {
        margin-bottom: 4rem;
    }
    .uui-padding-vertical-xhuge-6 {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
    .home-primary-lockup.desktop {
        display: none;
    }
    .hp-top-lockup.desktop {
        display: block;
    }
    .grid-4.desktop {
        display: none;
    }
    .blog-summary-card {
        margin-bottom: 40px;
    }
    .collection-list-2 {
        display: inline-block;
    }
    .patient-4up-block {
        margin-bottom: 40px;
    }
    .patient-4up-block.about {
        margin-bottom: 60px;
    }
    .div-block-2.wider.narrower {
        max-width: 60%;
    }
    .div-block-2.narrower {
        max-width: 50%;
        margin-left: auto;
        margin-right: auto;
    }
    .grid-5 {
        display: inline-block;
    }
    .patient-4up-offset {
        flex-direction: column;
        display: flex;
    }
    .grid-6 {
        display: inline-block;
    }
    .hp-stat-card {
        margin-bottom: 40px;
    }
    .header-home-mobile {
        display: block;
    }
    .inset-mobile {
        max-width: 75%;
    }
    .grid-8.mobile {
        display: block;
    }
    .mobile-grid {
        margin-bottom: 40px;
    }
    .home-primary-lockup-mobile,
    .grid-9 {
        display: block;
    }
    .mobile-clay-phone {
        max-width: 50%;
        margin-bottom: 60px;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    .logo-wrapper {
        width: 34vw;
    }
    .padded-logo {
        padding: 40px 20px;
    }
    .uui-blogpost03_content {
        margin-bottom: 3rem;
    }
    .uui-heading-large-5 {
        font-size: 2.75rem;
    }
    .uui-blogpost03_header {
        min-height: auto;
        grid-row-gap: 4rem;
        grid-template-columns: 1fr;
        grid-auto-flow: row;
    }
    .uui-padding-vertical-xhuge-7 {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
    .req-nav-button {
        display: none;
    }
    .req-nav-button.mobile {
        justify-content: center;
        margin-top: 20px;
        display: flex;
    }
}

@media screen and (max-width: 767px) {
    .uui-button {
        font-size: 1rem;
    }
    .uui-button:hover {
        background-color: #7f56d9;
        border-color: #7f56d9;
    }
    .uui-button-tertiary-gray:hover {
        color: #475467;
        background-color: rgba(0, 0, 0, 0);
    }
    .uui-button-secondary-gray {
        padding-left: 1.25rem;
        font-size: 1rem;
    }
    .uui-button-secondary-gray:hover {
        color: #344054;
        background-color: #fff;
    }
    .uui-navbar08_link {
        font-size: 1rem;
    }
    .uui-text-size-small.hide-mobile-landscape,
    .hide-mobile-landscape {
        display: none;
    }
    .uui-badge-small-success {
        padding: .125rem .5rem;
    }
    .uui-navbar08_dropdown-link-list {
        max-width: none;
        grid-row-gap: .5rem;
    }
    .uui-navbar08_dropdown-content {
        grid-row-gap: 1.5rem;
        flex-direction: column;
        grid-template-rows: auto;
        grid-template-columns: 1fr;
        grid-auto-columns: 1fr;
    }
    .uui-navbar08_dropdown-toggle {
        font-size: 1rem;
    }
    .uui-navbar08_menu {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .uui-styleguide_logomark-bg {
        background-image: url("https://uploads-ssl.webflow.com/6405f18ff0cf3cb688b7fba9/6406351dfd521d05ad29b75c_untitled-ui-logomark.svg");
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .uui-navbar08_component {
        min-height: 4.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .uui-heroheader08_image {
        height: 22rem;
    }
    .uui-heroheader08_form {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    .uui-text-size-xlarge {
        font-size: 1.125rem;
    }
    .uui-space-small {
        min-height: 1.25rem;
    }
    .uui-heading-xlarge {
        font-size: 2.25rem;
    }
    .uui-padding-vertical-xhuge {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
    .uui-page-padding {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .uui-button-wrapper.max-width-full-mobile-landscape {
        width: 100%;
        justify-content: center;
    }
    .max-width-full-mobile-landscape {
        width: 100%;
        max-width: none;
    }
    .uui-button-row {
        align-self: stretch;
    }
    .uui-button-row.button-row-center.is-reverse-mobile-landscape,
    .uui-button-row.is-reverse-mobile-landscape {
        flex-direction: column-reverse;
    }
    .uui-space-medium {
        min-height: 1.5rem;
    }
    .uui-text-size-large {
        font-size: 1rem;
    }
    .uui-space-xsmall {
        min-height: .75rem;
    }
    .uui-heading-medium {
        font-size: 1.75rem;
        line-height: 1.4;
    }
    .uui-layout21_item-list {
        margin-top: 1.5rem;
        margin-bottom: 2rem;
    }
    .uui-layout20_component-copy {
        grid-row-gap: 1rem;
        grid-template-columns: 1fr;
    }
    .uui-button-link {
        font-size: 1rem;
    }
    .uui-button-link:hover {
        color: #6941c6;
    }
    .uui-heading-xxsmall {
        font-size: 1.125rem;
    }
    .uui-space-xxlarge {
        min-height: 3rem;
    }
    .uui-layout72_item {
        padding: 1.5rem;
    }
    .uui-layout72_component {
        grid-row-gap: 1.5rem;
        grid-template-columns: 1fr;
    }
    .uui-layout94_item {
        padding: 1.5rem;
    }
    .uui-layout94_list {
        grid-template-columns: 1fr;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    .uui-layout94_component {
        align-items: stretch;
    }
    .uui-cta09_component {
        padding: 2.5rem 1.5rem;
    }
    .uui-footer03_legal-link:hover {
        color: #98a2b3;
    }
    .uui-footer03_legal-list {
        margin-bottom: 1rem;
    }
    .uui-footer03_bottom-wrapper {
        flex-direction: column-reverse;
        align-items: flex-start;
        margin-top: 3rem;
    }
    .uui-footer03_link:hover {
        color: #6941c6;
    }
    .uui-footer03_social-link:hover {
        color: #98a2b3;
    }
    .uui-footer03_social-list {
        margin-top: 1.5rem;
    }
    .uui-footer03_logo-link {
        margin-bottom: 1.5rem;
    }
    .uui-footer03_top-wrapper {
        grid-row-gap: 3rem;
    }
    .uui-padding-vertical-xlarge {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }
    .uui-button-2 {
        font-size: 1rem;
    }
    .uui-button-2:hover {
        background-color: #7f56d9;
        border-color: #7f56d9;
    }
    .uui-button-row-2 {
        align-self: stretch;
    }
    .uui-button-row-2.button-row-center.is-reverse-mobile-landscape,
    .uui-button-row-2.is-reverse-mobile-landscape {
        flex-direction: column-reverse;
    }
    .uui-space-small-2 {
        min-height: 1.25rem;
    }
    .uui-text-size-large-2 {
        font-size: 1rem;
    }
    .uui-heading-xxsmall-2 {
        font-size: 1.125rem;
    }
    .avatar-stack_component {
        margin-bottom: 1.5rem;
    }
    .uui-faq01_cta {
        margin-top: 3rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .uui-space-medium-2 {
        min-height: 1.5rem;
    }
    .uui-faq01_component {
        margin-top: 3rem;
    }
    .uui-space-xsmall-2 {
        min-height: .75rem;
    }
    .uui-heading-medium-2 {
        font-size: 1.75rem;
        line-height: 1.4;
    }
    .uui-padding-vertical-xhuge-2 {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
    .uui-page-padding-2 {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .uui-button-3 {
        font-size: 1rem;
    }
    .uui-button-3:hover {
        background-color: #7f56d9;
        border-color: #7f56d9;
    }
    .uui-button-wrapper-2.max-width-full-mobile-landscape {
        width: 100%;
    }
    .max-width-full-mobile-landscape-2 {
        width: 100%;
        max-width: none;
    }
    .uui-button-secondary-gray-2 {
        padding-left: 1.25rem;
        font-size: 1rem;
    }
    .uui-button-secondary-gray-2:hover {
        color: #344054;
        background-color: #fff;
    }
    .uui-button-row-3 {
        align-self: stretch;
    }
    .uui-button-row-3.is-reverse-mobile-landscape {
        flex-direction: column-reverse;
    }
    .uui-space-medium-3 {
        min-height: 1.5rem;
    }
    .uui-text-size-large-3 {
        font-size: 1rem;
    }
    .uui-space-xsmall-3 {
        min-height: .75rem;
    }
    .uui-heading-medium-3 {
        font-size: 1.75rem;
        line-height: 1.4;
    }
    .uui-layout49_image {
        width: 100%;
        min-width: 100%;
        position: static;
    }
    .uui-layout49_image-wrapper {
        min-height: auto;
        padding: 1.5rem 1rem;
    }
    .uui-layout49_component {
        grid-row-gap: 3rem;
        grid-template-columns: 1fr;
    }
    .uui-padding-vertical-xhuge-3 {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
    .uui-page-padding-3 {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .uui-heading-xxsmall-3 {
        font-size: 1.125rem;
    }
    .uui-space-xxlarge-2 {
        min-height: 3rem;
    }
    .uui-layout83_item {
        padding: 1.5rem;
    }
    .uui-layout83_list {
        grid-row-gap: 1.5rem;
        grid-template-columns: 1fr;
    }
    .uui-heading-medium-4 {
        font-size: 1.75rem;
        line-height: 1.4;
    }
    .uui-layout83_component {
        grid-row-gap: 3rem;
        grid-template-columns: 1fr;
    }
    .uui-layout83_component.padded-top {
        margin-top: 200px;
    }
    .uui-button-link-2 {
        font-size: 1rem;
    }
    .uui-button-link-2:hover {
        color: #6941c6;
    }
    .uui-space-small-3 {
        min-height: 1.25rem;
    }
    .uui-heading-xxsmall-4 {
        font-size: 1.125rem;
    }
    .uui-layout72_item-2 {
        padding: 1.5rem;
    }
    .uui-layout72_component-2 {
        grid-row-gap: 1.5rem;
        grid-template-columns: 1fr;
    }
    .uui-testimonial06_arrow:hover {
        background-color: #fff;
    }
    .hide-mobile-landscape-2 {
        display: none;
    }
    .uui-testimonial06_logo {
        max-height: 2.5rem;
    }
    .uui-testimonial06_divider {
        display: none;
    }
    .uui-testimonial06_client-info {
        margin-bottom: 1rem;
    }
    .uui-testimonial06_client-image-wrapper {
        margin-bottom: 1rem;
        margin-right: 0;
    }
    .uui-testimonial06_client {
        width: 100%;
        text-align: center;
        flex-direction: column;
        align-items: center;
    }
    .uui-testimonial06_content {
        grid-column-gap: 1.5rem;
        grid-row-gap: 1.5rem;
    }
    .uui-testimonial06_slide {
        padding-left: 0;
        padding-right: 0;
    }
    .uui-testimonial06_component {
        padding-bottom: 3rem;
    }
    .uui-heading-xxsmall-5 {
        font-size: 1.125rem;
    }
    .uui-space-xxlarge-3 {
        min-height: 3rem;
    }
    .uui-layout83_item-2 {
        padding: 1.5rem;
    }
    .uui-layout83_list-2 {
        grid-row-gap: 1.5rem;
        grid-template-columns: 1fr;
    }
    .uui-button-4 {
        font-size: 1rem;
    }
    .uui-button-4:hover {
        background-color: #7f56d9;
        border-color: #7f56d9;
    }
    .uui-button-wrapper-3.max-width-full-mobile-landscape {
        width: 100%;
    }
    .max-width-full-mobile-landscape-3 {
        width: 100%;
        max-width: none;
    }
    .uui-button-secondary-gray-3 {
        padding-left: 1.25rem;
        font-size: 1rem;
    }
    .uui-button-secondary-gray-3:hover {
        color: #344054;
        background-color: #fff;
    }
    .uui-button-row-4 {
        align-self: stretch;
    }
    .uui-button-row-4.is-reverse-mobile-landscape {
        flex-direction: column-reverse;
    }
    .uui-space-medium-4 {
        min-height: 1.5rem;
    }
    .uui-text-size-large-4 {
        font-size: 1rem;
    }
    .uui-space-xsmall-4 {
        min-height: .75rem;
    }
    .uui-heading-medium-5 {
        font-size: 1.75rem;
        line-height: 1.4;
    }
    .uui-layout83_component-2 {
        grid-row-gap: 3rem;
        grid-template-columns: 1fr;
    }
    .uui-padding-vertical-xhuge-4 {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
    .uui-page-padding-4 {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .uui-button-link-3 {
        font-size: 1rem;
    }
    .uui-button-link-3:hover {
        color: #6941c6;
    }
    .uui-space-small-4 {
        min-height: 1.25rem;
    }
    .uui-heading-xsmall {
        font-size: 1.25rem;
    }
    .uui-blog04_title-link.featured-article {
        margin-bottom: .5rem;
    }
    .uui-blog04_category-link {
        padding: .125rem .5rem;
        font-size: .75rem;
    }
    .uui-blog04_meta-wrapper {
        font-size: .75rem;
    }
    .uui-blog04_image-link {
        margin-bottom: 1.5rem;
    }
    .uui-blog04_image-link:hover {
        box-shadow: none;
    }
    .uui-blog04_list {
        grid-row-gap: 3rem;
        grid-template-columns: 1fr;
    }
    .uui-blog04_menu-link:hover {
        color: #667085;
        border-bottom-style: none;
    }
    .uui-blog04_menu-link.last-link {
        margin-right: 2rem;
    }
    .uui-blog04_category-menu {
        width: 100vw;
        margin-bottom: 3rem;
        margin-left: -5vw;
        padding-left: 5vw;
        overflow: scroll;
    }
    .uui-blog04_category-menu.no-scrollbar {
        justify-content: flex-start;
    }
    .uui-heading-small {
        font-size: 1.5rem;
    }
    .uui-blog04_featured-item-content {
        padding: 0;
    }
    .uui-blog04_featured-item {
        grid-row-gap: 1.5rem;
        grid-template-columns: 1fr;
        margin-bottom: 3rem;
    }
    .uui-blog04_component {
        margin-top: 4rem;
    }
    .uui-text-size-large-5 {
        font-size: 1rem;
    }
    .uui-heading-large {
        font-size: 2.25rem;
    }
    .uui-heading-xsmall-2 {
        font-size: 1.25rem;
    }
    .uui-blogpost04_newsletter {
        padding: 1.5rem 1.5rem 2rem;
    }
    .uui-text-rich-text figure {
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
    }
    .uui-text-rich-text figcaption {
        margin-top: .75rem;
    }
    .uui-blogpost04_content,
    .uui-blogpost04_author-wrapper {
        margin-bottom: 2rem;
    }
    .uui-blogpost04_content-top {
        flex-direction: column;
        margin-bottom: 3rem;
    }
    .uui-blogpost04_image-wrapper {
        margin-top: 3rem;
        margin-bottom: 2rem;
    }
    .uui-text-size-xlarge-2 {
        font-size: 1.125rem;
    }
    .uui-heading-large-2 {
        font-size: 2.25rem;
    }
    .uui-blogpost04_category-link {
        padding: .125rem .5rem;
        font-size: .75rem;
    }
    .uui-blogpost04_meta-wrapper {
        margin-top: 2rem;
        font-size: .75rem;
    }
    .uui-button-link-4 {
        font-size: 1rem;
    }
    .uui-button-link-4:hover {
        color: #6941c6;
    }
    .uui-text-size-large-6 {
        font-size: 1rem;
    }
    .uui-heading-medium-6 {
        font-size: 1.75rem;
        line-height: 1.4;
    }
    .uui-cta06_content {
        margin-right: 0;
    }
    .uui-cta06_component {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .uui-button-tertiary-gray-2:hover {
        color: #475467;
        background-color: rgba(0, 0, 0, 0);
    }
    .uui-navbar08_link-2 {
        font-size: 1rem;
    }
    .uui-text-size-small-4.hide-mobile-landscape,
    .hide-mobile-landscape-3 {
        display: none;
    }
    .uui-badge-small-success-2 {
        padding: .125rem .5rem;
    }
    .uui-navbar08_dropdown-link-list-2 {
        max-width: none;
        grid-row-gap: .5rem;
    }
    .uui-navbar08_dropdown-content-2 {
        grid-row-gap: 1.5rem;
        flex-direction: column;
        grid-template-rows: auto;
        grid-template-columns: 1fr;
        grid-auto-columns: 1fr;
    }
    .uui-navbar08_dropdown-toggle-2 {
        font-size: 1rem;
    }
    .uui-navbar08_menu-2 {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .uui-styleguide_logomark-bg-2 {
        background-image: url("https://uploads-ssl.webflow.com/6405f18ff0cf3cb688b7fba9/6406351dfd521d05ad29b75c_untitled-ui-logomark.svg");
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .uui-navbar08_component-2 {
        min-height: 4.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .uui-button-5 {
        font-size: 1rem;
    }
    .uui-button-5:hover {
        background-color: #7f56d9;
        border-color: #7f56d9;
    }
    .uui-header09_form {
        margin-top: 2rem;
    }
    .uui-text-size-xlarge-3 {
        font-size: 1.125rem;
    }
    .uui-space-small-5 {
        min-height: 1.25rem;
    }
    .uui-heading-large-3 {
        font-size: 2.25rem;
    }
    .uui-padding-vertical-xhuge-5 {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
    .uui-page-padding-5 {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .uui-cta10_form {
        margin-top: 2rem;
    }
    .uui-text-size-large-7 {
        font-size: 1rem;
    }
    .uui-space-xsmall-5 {
        min-height: .75rem;
    }
    .uui-heading-medium-7 {
        font-size: 1.75rem;
        line-height: 1.4;
    }
    .uui-blogpost01_author-heading {
        font-size: 1rem;
    }
    .uui-blogpost01_author-image {
        width: 3rem;
        height: 3rem;
        min-height: 3rem;
        min-width: 3rem;
    }
    .uui-blogpost01_author-image-wrapper {
        margin-right: .75rem;
    }
    .uui-blogpost01_divider {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    .uui-badge {
        padding: .125rem .5rem;
    }
    .uui-button-secondary-gray-4 {
        padding-left: 1.25rem;
        font-size: 1rem;
    }
    .uui-button-secondary-gray-4:hover {
        color: #344054;
        background-color: #fff;
    }
    .uui-text-rich-text-2 figure {
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
    }
    .uui-text-rich-text-2 figcaption {
        margin-top: .75rem;
    }
    .uui-blogpost01_content {
        margin-bottom: 2rem;
    }
    .uui-blogpost01_content-top {
        margin-top: 2rem;
    }
    .uui-heading-large-4 {
        font-size: 2.25rem;
    }
    .uui-blogpost01_title-wrapper {
        margin-bottom: 3rem;
    }
    .uui-padding-vertical-xhuge-6 {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
    .uui-page-padding-6 {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .grid-3 {
        display: inline-block;
    }
    .shadow-box {
        margin-bottom: 20px;
    }
    .grid-8.mobile {
        display: block;
    }
    .mobile-grid {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .uui-blogpost03_author-heading-bottom {
        font-size: 1rem;
    }
    .uui-blogpost03_author-image {
        width: 3rem;
        height: 3rem;
        min-height: 3rem;
        min-width: 3rem;
    }
    .uui-blogpost03_author-image-wrapper {
        margin-right: .75rem;
    }
    .uui-blogpost03_divider {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    .uui-badge-2 {
        padding: .125rem .5rem;
    }
    .uui-button-secondary-gray-5 {
        padding-left: 1.25rem;
        font-size: 1rem;
    }
    .uui-button-secondary-gray-5:hover {
        color: #344054;
        background-color: #fff;
    }
    .uui-text-rich-text-3 figure {
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
    }
    .uui-text-rich-text-3 figcaption {
        margin-top: .75rem;
    }
    .uui-blogpost03_content {
        margin-bottom: 2rem;
    }
    .uui-blogpost03_date-wrapper {
        margin-bottom: .75rem;
    }
    .uui-text-size-large-8 {
        font-size: 1rem;
    }
    .uui-blogpost03_content-top {
        margin-top: 2rem;
    }
    .uui-heading-large-5 {
        font-size: 2.25rem;
    }
    .uui-blogpost03_header {
        grid-row-gap: 3rem;
        margin-bottom: 4rem;
    }
    .uui-padding-vertical-xhuge-7 {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
    .uui-page-padding-7 {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

@media screen and (max-width: 479px) {
    .uui-button.button-inverse {
        background-color: #315074;
    }
    .uui-navbar08_dropdown-link {
        padding-left: 0;
    }
    .uui-navbar08_dropdown-content {
        grid-template-columns: 1fr;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .uui-navbar08_container {
        flex: 0 auto;
        display: flex;
    }
    .uui-navbar08_component {
        border-radius: 0;
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        display: flex;
    }
    .uui-heroheader08_image {
        height: 17.5rem;
    }
    .ratings_wrapper {
        flex: none;
        margin-left: .75rem;
    }
    .uui-signup-form_wrapper {
        grid-row-gap: .75rem;
        flex-direction: column;
        grid-template-columns: 1fr;
    }
    .uui-heroheader08_component {
        grid-template-columns: 1fr;
    }
    .uui-page-padding {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .uui-page-padding.dark-blue.bottom-cta {
        padding-left: 1rem;
    }
    .uui-section_heroheader08 {
        overflow: hidden;
    }
    .uui-section_heroheader08.mobile {
        display: block;
    }
    .uui-button-wrapper.max-width-full-mobile-landscape {
        justify-content: center;
    }
    .uui-heading-medium {
        font-size: 1.5rem;
    }
    .uui-heading-medium.white.narrow {
        max-width: 100%;
        font-size: 1.5rem;
    }
    .uui-heading-medium._4-up.green,
    .uui-heading-medium._4-up.red {
        font-size: 26px;
    }
    .uui-footer03_legal-list {
        display: flex;
    }
    .uui-footer03_link-list {
        margin-top: 20px;
    }
    .uui-footer03_menu-wrapper {
        grid-template-columns: 1fr;
    }
    .uui-footer03_menu-wrapper.mobile {
        grid-column-gap: 2rem;
        grid-row-gap: 0rem;
        grid-template-rows: auto;
        grid-template-columns: 1fr;
        grid-auto-columns: 1fr;
        display: flex;
    }
    .uui-footer03_menu-wrapper.desktop {
        display: none;
    }
    .uui-footer03_component {
        max-width: 100%;
        overflow: hidden;
    }
    .image.offset-left {
        margin-left: -9px;
    }
    .uui-section_faq01.padded-top {
        margin-top: 910px;
    }
    .uui-heading-medium-3 {
        font-size: 1.55rem;
    }
    .uui-heading-medium-3.yellow-pill {
        margin-left: 0;
        font-size: 11px;
    }
    .uui-heading-medium-3.centered.padded-bottom {
        margin-left: 10px;
        margin-right: 10px;
    }
    .uui-heading-medium-3.mobile-news-heading {
        margin-bottom: 2rem;
    }
    .uui-padding-vertical-xhuge-3.no-top-padding {
        margin-top: -80px;
        padding-top: 10px;
    }
    .uui-section_layout49 {
        margin-top: 930px;
    }
    .uui-section_layout49.for-patients {
        margin-top: 910px;
    }
    .uui-layout83_item {
        padding-left: 0;
        padding-right: 0;
    }
    .uui-layout83_list {
        grid-row-gap: 1.5rem;
        grid-template-columns: 1fr;
    }
    .uui-section_layout83 {
        margin-top: 890px;
    }
    .uui-section_layout83.no-top-padding {
        margin-top: 0;
    }
    .green-wrapper {
        padding-left: 20px;
        padding-right: 20px;
    }
    .uui-heading-tiny._4-up {
        font-size: 14px;
    }
    .uui-layout83_item-2.grid-offset-1.no-top-padding.mobile-grid.xtra-top-padding {
        margin-top: 60px;
    }
    .uui-layout83_item-2.grif-offset-2 {
        margin-top: 40px;
    }
    .uui-layout83_item-2.grif-offset-2.xtra-top-padding,
    .uui-layout83_item-2.grid-offset-3.xtra-top-padding,
    .uui-layout83_item-2.grid-offset-4,
    .uui-layout83_item-2.grid-offset-4.xtra-top-padding {
        margin-top: 60px;
    }
    .uui-layout83_item-2.about-grid-offset-1 {
        margin-top: 30px;
        margin-bottom: 0;
    }
    .uui-layout83_item-2.about-grid-offset-4 {
        margin-top: -60px;
    }
    .uui-layout83_item-2.about-grid-offset-4.xtra-top-padding {
        margin-top: -40px;
    }
    .uui-layout83_item-2.mobile-grid {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .uui-layout83_item-2.mobile-grid.xtra-top-padding {
        margin-top: 60px;
    }
    .uui-layout83_list-2 {
        grid-row-gap: 1.5rem;
        grid-template-columns: 1fr;
    }
    .collection-list {
        display: block;
    }
    .uui-blogpost04_form-wrapper {
        grid-row-gap: 12px;
        flex-direction: column;
        grid-template-columns: 1fr;
    }
    .uui-blogpost04_author-wrapper {
        margin-bottom: 1rem;
    }
    .uui-blogpost04_content-top {
        flex-direction: column;
        align-items: flex-start;
    }
    .uui-signup-form_wrapper-2 {
        grid-row-gap: .75rem;
        flex-direction: column;
        grid-template-columns: 1fr;
    }
    .uui-navbar08_dropdown-link-2 {
        padding-left: 0;
    }
    .uui-navbar08_dropdown-content-2 {
        grid-template-columns: 1fr;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .uui-navbar08_component-2 {
        box-shadow: none;
        border-radius: 0;
        margin-left: 0;
        margin-right: 0;
    }
    .uui-signup-form_wrapper-3 {
        grid-row-gap: .75rem;
        flex-direction: column;
        grid-template-columns: 1fr;
    }
    .grid {
        display: block;
    }
    .press-item-title {
        font-size: 14px;
        line-height: 16px;
    }
    .uui-blogpost01_author-wrapper {
        margin-bottom: 1rem;
    }
    .uui-blogpost01_tag-list {
        justify-content: flex-start;
    }
    .uui-blogpost01_tag-list-wrapper {
        width: 100%;
    }
    .uui-blogpost01_share-wrapper {
        width: 100%;
        margin-bottom: 1.5rem;
    }
    .uui-blogpost01_content-bottom,
    .uui-blogpost01_content-top {
        flex-direction: column;
        align-items: flex-start;
    }
    .grid-2 {
        display: inline-block;
    }
    .grid-3 {
        display: block;
    }
    .shadow-box {
        margin-bottom: 20px;
    }
    .hp-love {
        margin-top: 160px;
        padding-top: 40px;
    }
    .blog-digest-header {
        flex-direction: column;
    }
    .patient-4up-block {
        margin-top: 0;
        margin-bottom: 60px;
        padding-top: 40px;
    }
    .div-block-2.wider.narrower,
    .div-block-2.narrower {
        max-width: 100%;
    }
    .grid-5.inset {
        margin-left: 10px;
        margin-right: 10px;
    }
    .patient-4up-offset {
        margin-bottom: 0;
    }
    .grid-7 {
        display: inline-block;
    }
    .founded-by-text {
        margin-left: 20px;
        margin-right: 20px;
    }
    .hp-stat-card {
        margin-top: 40px;
        margin-bottom: 20px;
    }
    .hp-stat-card.xtra-top-padding {
        margin-top: 60px;
    }
    .inset-mobile {
        max-width: 100%;
    }
    .learn-more-3up {
        display: none;
    }
    .learn-more-vertical {
        display: block;
    }
    .grid-8.mobile {
        grid-column-gap: 16px;
        grid-row-gap: 16px;
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-auto-columns: 1fr;
        grid-auto-flow: row;
        display: block;
    }
    .mobile-grid {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .mobile-clay-phone {
        max-width: 80%;
    }
    .logo-wrapper {
        width: 34vw;
    }
    .uui-blogpost03_tag-list {
        justify-content: flex-start;
    }
    .uui-blogpost03_tag-list-wrapper {
        width: 100%;
    }
    .uui-blogpost03_share-wrapper {
        width: 100%;
        margin-bottom: 1.5rem;
    }
    .uui-blogpost03_content-bottom {
        flex-direction: column;
        align-items: flex-start;
    }
    .uui-blogpost03_header {
        grid-template-columns: 1fr;
    }
}

#w-node-b2ee93d5-8927-6b7c-b5c5-de0a9fa9026e-dcb7fbaa {
    align-self: end;
}

#w-node-_407e1512-2799-d3f5-ad71-5a62e9391d36-dcb7fbaa {
    grid-area: span 1 / span 1 / span 1 / span 1;
    align-self: auto;
}

#w-node-e187ed50-b0c2-a1ed-1ce3-c3124b6389b1-dcb7fbaa {
    grid-area: span 1 / span 1 / span 1 / span 1;
    align-self: center;
    justify-self: center;
}

#w-node-_0034e0c7-bf26-6637-841e-4417a4870640-dcb7fbaa,
#w-node-_6704c5c9-6e1e-da03-b9ba-9279269afd31-dcb7fbaa,
#w-node-e3c68ccf-450e-4b5e-9298-3b9f76f293db-dcb7fbaa,
#w-node-ba651f66-b059-5406-90e8-c23e9c7293de-dcb7fbaa,
#w-node-_29fd6a43-333a-aa23-5cd2-4ebbe8864cb7-dcb7fbaa,
#w-node-_2ea0b4ba-1550-d9c4-2bcc-cda5888dd547-dcb7fbaa,
#w-node-_3d532913-e665-0fa2-f910-a4f05f4e4023-dcb7fbaa,
#w-node-_56578e1e-be48-abf0-1597-8096820768c2-dcb7fbaa,
#w-node-e5a543c5-1ef1-a30f-597d-d07f736ad640-dcb7fbaa {
    grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0a3e12a5-1482-5fd4-8145-98469941769b-9941768b,
#w-node-_0fd0057e-88ad-6643-627a-61eeac26d8a5-9941768b {
    justify-self: stretch;
}

#w-node-b5f7792b-63ed-43aa-9b62-7df0e7eb5d59-6c694fd7 {
    align-self: auto;
}

#w-node-b5f7792b-63ed-43aa-9b62-7df0e7eb5d5c-6c694fd7,
#w-node-b5f7792b-63ed-43aa-9b62-7df0e7eb5d62-6c694fd7 {
    align-self: center;
}

#w-node-b5f7792b-63ed-43aa-9b62-7df0e7eb5d68-6c694fd7 {
    grid-area: span 1 / span 1 / span 1 / span 1;
    align-self: center;
}

#w-node-b5f7792b-63ed-43aa-9b62-7df0e7eb5d6e-6c694fd7,
#w-node-b5f7792b-63ed-43aa-9b62-7df0e7eb5d90-6c694fd7,
#w-node-b5f7792b-63ed-43aa-9b62-7df0e7eb5d96-6c694fd7,
#w-node-b5f7792b-63ed-43aa-9b62-7df0e7eb5d9c-6c694fd7,
#w-node-_377f65bf-5075-1bbf-46d3-6dca70f63771-6c694fd7,
#w-node-_377f65bf-5075-1bbf-46d3-6dca70f63777-6c694fd7,
#w-node-_377f65bf-5075-1bbf-46d3-6dca70f6377d-6c694fd7 {
    align-self: center;
}

#providers-form.w-node-cd78c66f-6f6d-ef76-b6e1-4dccc2302dc4-74fa0309,
#w-node-_306cf37e-e01e-523b-ba5a-f2f1e386662a-74fa0309 {
    grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-f4538a70-ff5d-2931-bc4d-3be84fe7184e-74fa0309 {
    align-self: center;
}

#w-node-f4538a70-ff5d-2931-bc4d-3be84fe7185d-74fa0309,
#w-node-f4538a70-ff5d-2931-bc4d-3be84fe71866-74fa0309,
#w-node-f4538a70-ff5d-2931-bc4d-3be84fe7186f-74fa0309,
#w-node-f4538a70-ff5d-2931-bc4d-3be84fe71878-74fa0309 {
    align-self: start;
}

#w-node-_9d1a2266-ac9e-9f24-0e50-9bfba4e8a31e-74fa0309,
#w-node-_1bd3e8eb-d92c-dc0e-f314-ba6bbe6281b6-74fa0309,
#w-node-b91d75e9-d0b1-3d0a-cf8e-abe9445cc888-74fa0309,
#w-node-_7b804ddd-a112-ba32-5d09-7917df24b031-74fa0309 {
    grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_98009358-a2b8-3ae4-cfa0-01e55a750081-7e3a2438 {
    align-self: end;
}

#w-node-a57e5573-16d2-2f11-e885-950eaaa37b7f-7e3a2438,
#w-node-a57e5573-16d2-2f11-e885-950eaaa37b88-7e3a2438,
#w-node-a57e5573-16d2-2f11-e885-950eaaa37b91-7e3a2438,
#w-node-a57e5573-16d2-2f11-e885-950eaaa37b9a-7e3a2438 {
    align-self: start;
}

#w-node-_98009358-a2b8-3ae4-cfa0-01e55a750081-e484c423 {
    align-self: end;
}

#w-node-_14a2f319-ec21-bc25-0361-29b492e17a2f-e484c423 {
    grid-area: span 1 / span 1 / span 1 / span 1;
    align-self: auto;
}

#w-node-_14a2f319-ec21-bc25-0361-29b492e17a42-e484c423 {
    grid-area: span 1 / span 1 / span 1 / span 1;
    align-self: center;
    justify-self: center;
}

#w-node-_14a2f319-ec21-bc25-0361-29b492e17a44-e484c423,
#w-node-_56a448ac-fc30-1e23-6ae7-8afaa6f4229a-e484c423,
#w-node-d1a9cca2-5deb-f3b6-77c9-19d41778bbb1-8449551f,
#w-node-_812733cf-8ee5-5558-9954-6856c5a6edfa-8449551f {
    grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_6637e764-ee54-71f3-4aff-7b7853312a9b-8449551f {
    align-self: center;
}

#w-node-_6637e764-ee54-71f3-4aff-7b7853312aae-8449551f,
#w-node-_6637e764-ee54-71f3-4aff-7b7853312ab8-8449551f,
#w-node-_6637e764-ee54-71f3-4aff-7b7853312ac2-8449551f,
#w-node-_6637e764-ee54-71f3-4aff-7b7853312acc-8449551f {
    align-self: start;
}

#w-node-cd78c66f-6f6d-ef76-b6e1-4dccc2302dc4-0d2c7a42,
#w-node-_664ad715-558a-8513-1db4-19855b38907f-75271419,
#w-node-_2dc3bb91-9e11-a1f1-e93b-83790cba20f6-75271419 {
    grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-f1831603-83e6-0278-3bee-be109d42aa8c-75271419 {
    align-self: center;
}

#w-node-f1831603-83e6-0278-3bee-be109d42aaa4-75271419,
#w-node-f1831603-83e6-0278-3bee-be109d42aaad-75271419 {
    align-self: start;
}

#w-node-_9a81edbf-3fcd-347f-f4de-39cee3bbbffe-75271419 {
    grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-f1831603-83e6-0278-3bee-be109d42aab6-75271419 {
    align-self: start;
}

#w-node-fa4ad5c4-3bfe-d0cb-dfa7-a51208b45d38-75271419,
#w-node-_20e635cc-129d-efaa-a57a-e359132aa8fc-75271419,
#w-node-e1c4244b-5bba-d9ae-d3e6-74d33660ed80-75271419,
#w-node-_43e813a1-b595-d364-c073-e2d6940db9d1-75271419,
#w-node-def2ef24-9b30-a2c9-5f08-aae7eab7641f-75271419,
#w-node-ef4c062a-d66b-7516-413b-ead1bf532326-75271419 {
    grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-deecc964-ca17-c041-887e-0fdfed20cd87-79e1e9dd {
    grid-area: span 1 / span 1 / span 1 / span 1;
    align-self: center;
    justify-self: center;
}

#w-node-cf432f7e-efd2-ae26-99df-f7af7b11cf8e-79e1e9dd {
    grid-area: span 1 / span 1 / span 1 / span 1;
    justify-self: start;
}

#w-node-e86d7f09-7e48-e82d-f910-c5035e78864e-3e76fc13 {
    grid-area: span 1 / span 1 / span 1 / span 1;
}

@media screen and (max-width: 767px) {
    #w-node-_8922e9f0-e981-06b8-34b9-669f72630559-74fa0309,
    #w-node-ec455484-53b7-66d3-eaba-d48702c7002f-7e3a2438 {
        order: -9999;
    }
}

@font-face {
    font-family: 'Rubik variablefont wght';
    src: url('https://uploads-ssl.webflow.com/6405f18ff0cf3cb688b7fba9/6406375b573d417fbede1ac6_Rubik-VariableFont_wght.ttf') format('truetype');
    font-weight: 300 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik variablefont wght';
    src: url('https://uploads-ssl.webflow.com/6405f18ff0cf3cb688b7fba9/6406375bd70b3b0bbd3896de_Rubik-Italic-VariableFont_wght.ttf') format('truetype');
    font-weight: 300 900;
    font-style: italic;
    font-display: swap;
}