.error-message {
    width: 100%;
    font-size: 16px;
    color: #fff;
}

.error-message .icon-white {
    top: -2px;
    position: relative;
}

.success-message {
    max-width: 400px;
    width: 400px;
    min-width: 100px;
    min-height: 98px;
    max-height: 152px;
    font-size: 18px;
    position: relative;
    padding-left: 70px!important;
    padding-right: 75px!important;
    margin: 20px 0px;
}

.success-message .top-left {
    position: absolute;
    left: 30px;
    top: 30px;
}

.success-message .top-right {
    position: absolute;
    top: 30px;
    right: 30px;
}

.bg-yellow-50 {
    background-color: rgba(255, 251, 235, 1) !important
}

.text-yellow-700 {
    color: rgba(180, 83, 9, 1) !important;
}

.text-yellow-400 {
    color: rgba(251, 191, 36, 1) !important;
}

.bg-red-50 {
    background-color: rgba(254, 242, 242, 1) !important;
}

.text-red-400 {
    color: rgba(248, 113, 113, 1) !important;
}

.text-red-700 {
    color: rgba(185, 28, 28, 1) !important;
}