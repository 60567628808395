            /****  floating-Lable style start ****/
            
            .floating-label {
                position: relative;
                margin-bottom: 20px;
            }
            
            .floating-input,
            .floating-select,
            .credit-input {
                font-size: 16px;
                padding: 4px 4px;
                display: block;
                width: 100%;
                height: 48px;
                border: none;
                border: 1px solid #757575;
                border-radius: 4px;
                font-family: 'Source Sans Pro', sans-serif;
                padding: 25px 16px 16px 16px;
                margin: 0px 0px 16px 0px;
                background: #fff;
            }
            
            input[type="date"].floating-input {
                -webkit-appearance: none;
            }
            
            .select-container .floating-input {
                padding-top: 25px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
            
            .floating-input:focus,
            .floating-select:focus {
                outline: none;
                border: 1px solid #1a1a1a;
                font-size: 16px;
            }
            
            .floating-label label {
                color: #757575;
                font-size: 16px;
                font-weight: normal;
                position: absolute;
                pointer-events: none;
                left: 18px;
                top: 14px;
                transition: 0.2s ease all;
                -moz-transition: 0.2s ease all;
                -webkit-transition: 0.2s ease all;
                font-family: 'Source Sans Pro', sans-serif;
            }
            
            textarea.floating-input {
                height: 200px;
                padding-top: 15px;
                line-height: 20px;
            }
            
            .select-dropdown {
                outline: none;
                cursor: pointer;
            }
            
            .select-option {
                border-bottom: 1px solid #757575;
                height: 48px;
                padding: 18px 16px 16px 16px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            
            .select-option.selected {
                background: #3c7ab5;
                color: #fff !important;
            }
            
            .select-option:last-child {
                border: none
            }
            
            .select-option:hover {
                background: #3c7ab5;
                cursor: pointer;
                color: #fff;
            }
            
            .floating-select-dropdown,
            .rbt-menu {
                border: 1px solid #757575;
                border-radius: 4px;
                position: absolute;
                left: 0px;
                top: 46px;
                background: #fff;
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
                z-index: 1000;
            }
            
            .rbt-menu>a {
                border-bottom: 1px solid #757575;
                height: 48px;
                padding: 18px 16px 16px 16px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                display: block;
            }
            
            .rbt-menu-pagination-option {
                border-bottom: 1px solid #757575;
                height: 48px;
                padding: 18px 16px 16px 16px !important;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                display: block;
            }
            
            .normal-typeahead .rbt-menu>a {
                padding: 0;
            }
            
            .normal-typeahead .rbt-menu>a div.select-option-type {
                background: #fff;
                padding: 0px;
                position: relative;
            }
            
            .normal-typeahead .rbt-menu>a div.select-option-type:hover {
                background: #3c7ab5;
                cursor: pointer;
                color: #fff;
            }
            
            .normal-typeahead .rbt-menu>a div.select-option-type:hover span {
                color: #fff;
            }
            
            .normal-typeahead .rbt-menu>a div.select-option-type span {
                padding: 18px 25px 16px 16px;
                display: block;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                color: black;
            }
            
            .normal-typeahead .rbt-menu>a div.select-option-type.selected {
                background: #3c7ab5;
                color: #fff !important;
            }
            
            .normal-typeahead .rbt-menu>a div.select-option-type.selected span {
                background: #3c7ab5;
                color: #fff !important;
            }
            
            .normal-typeahead .rbt-menu>a.active div.select-option-type {
                background: #3c7ab5;
                color: #fff !important;
            }
            
            .normal-typeahead .rbt-menu>a.active div.select-option-type span {
                color: #fff !important;
            }
            
            .normal-typeahead .rbt-menu>a div.select-option-type .selected-checkmark {
                position: absolute;
                right: 8px;
                top: 37%;
            }
            
            .rbt-highlight-text {
                background-color: #fff;
            }
            
            .right-icon {
                position: absolute;
                right: 10px;
                top: 15px;
                transition: 0.30s;
                -webkit-transition: 0.30s;
                -moz-transition: 0.30s;
                -ms-transition: 0.30s;
                -o-transition: 0.30s;
            }
            
            .left-icon-input {
                padding-left: 46px;
            }
            
            .left-icon-label {
                left: 55px !important;
            }
            
            .left-icon {
                position: absolute;
                left: 25px;
                top: 15px;
                transition: 0.30s;
                -webkit-transition: 0.30s;
                -moz-transition: 0.30s;
                -ms-transition: 0.30s;
                -o-transition: 0.30s;
            }
            
            .select-down-arrow {
                position: absolute;
                right: 10px;
                top: 20px;
                transition: 0.30s;
                -webkit-transition: 0.30s;
                -moz-transition: 0.30s;
                -ms-transition: 0.30s;
                -o-transition: 0.30s;
            }
            
            .select-down-arrow.active,
            .arrow-active,
            .accordian-arrow-active {
                transition: 0.30s;
                -webkit-transition: 0.30s;
                -moz-transition: 0.30s;
                -ms-transition: 0.30s;
                -o-transition: 0.30s;
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -o-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                transform: rotate(180deg);
            }
            
            .right-arrow-active {
                transition: 0.30s;
                -webkit-transition: 0.30s;
                -moz-transition: 0.30s;
                -ms-transition: 0.30s;
                -o-transition: 0.30s;
                -webkit-transform: rotate(90deg);
                -moz-transform: rotate(90deg);
                -o-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                transform: rotate(90deg);
            }
            
            .selected-checkmark {
                text-align: right;
                float: right;
            }
            
            .floating-select-dropdown.down {
                animation-name: fadeIn;
                display: block
            }
            
            .floating-select-dropdown.up {
                animation-name: fadeOut;
            }
            
            .select-dropdown:focus .floating-select-dropdown {
                visibility: visible;
                /* shows sub-menu */
                opacity: 1;
                z-index: 1;
                transform: translateY(0%);
                transition-delay: 0s, 0s, 0.3s;
            }
            
            .floating-select+label {
                top: 13px;
                font-size: 16px;
            }
            
            .floating-input:focus~label,
            .floating-input:not(:placeholder-shown)~label,
            .floating-label label.placeholder-active {
                top: 2px;
                font-size: 12px;
                color: #757575;
            }
            
            .floating-select:focus~label,
            .floating-select:not([value=""]):valid~label {
                top: -18px;
                font-size: 12px;
                color: #757575;
            }
            
            .floating-select:hover,
            .floating-input:hover {
                border: 1px solid #1a1a1a;
            }
            
            .floating-select:disabled,
            .floating-input:disabled {
                border: 1px solid #949494;
                color: #949494;
                background-color: #e9ecef;
            }
            
            .floating-input:disabled+label {
                color: #949494;
            }
            
            .floating-select.error,
            .floating-input.error,
            .rbt.error input {
                border: 1px solid #D8003b;
                color: #252733;
            }
            
            .floating-select.error+label,
            .floating-input.error+label,
            .rbt.error input+label {
                color: #D8003b
            }
            
            .floating-label .error-msg,
            .error-msg {
                font-size: 12px;
                color: #D8003b;
                margin-left: 0px;
                position: absolute;
                bottom: -18px;
            }
            
            .credit-input-error {
                font-size: 12px;
                color: #D8003b;
                margin-left: 0px;
                margin-top: 0px !important;
                position: absolute;
                bottom: -3px;
            }
            /* active state */
            
            .floating-input:focus~.bar:before,
            .floating-input:focus~.bar:after,
            .floating-select:focus~.bar:before,
            .floating-select:focus~.bar:after {
                width: 50%;
            }
            
            *,
            *:before,
            *:after {
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
            }
            
            .floating-textarea {
                min-height: 30px;
                max-height: 260px;
                overflow: hidden;
                overflow-x: hidden;
            }
            /* highlighter */
            
            .floating-label .highlight {
                position: absolute;
                height: 50%;
                width: 100%;
                top: 15%;
                left: 10px;
                pointer-events: none;
                opacity: 0.5;
            }
            /* active state */
            
            .floating-input:focus~.highlight,
            .floating-select:focus~.highlight {
                -webkit-animation: inputHighlighter 0.3s ease;
                -moz-animation: inputHighlighter 0.3s ease;
                animation: inputHighlighter 0.3s ease;
            }
            /* animation */
            
            .radio-container {
                margin: 24px 0;
                display: flex;
                cursor: pointer;
                user-select: none;
                display: inline-block;
            }
            
            .radio-outer-circle {
                width: 24px;
                height: 24px;
                min-width: 24px;
                min-height: 24px;
                border: 1px solid #3C7AB5;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 12px;
                transition: all 0.1s linear;
                position: relative;
            }
            
            .radio-inner-circle {
                width: 18px;
                height: 18px;
                min-width: 18px;
                min-height: 18px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all 0.1s linear;
                position: absolute;
                top: 10%;
                left: 10%;
            }
            
            .unselected {
                border: 1px solid #666;
            }
            
            .unselected-circle {
                width: 0;
                height: 0;
            }
            
            .helper-text {
                color: #666;
                padding-right: 8px;
                position: relative;
                top: -7px;
            }
            
            @-webkit-keyframes inputHighlighter {
                from {
                    background: #5264AE;
                }
                to {
                    width: 0;
                    background: transparent;
                }
            }
            
            @-moz-keyframes inputHighlighter {
                from {
                    background: #5264AE;
                }
                to {
                    width: 0;
                    background: transparent;
                }
            }
            
            @keyframes inputHighlighter {
                from {
                    background: #5264AE;
                }
                to {
                    width: 0;
                    background: transparent;
                }
            }
            
            @keyframes fadeIn {
                0% {
                    opacity: 0;
                    transform: translateY(startYposition);
                }
                100% {
                    opacity: 1;
                    transform: translateY(endYposition);
                }
            }
            
            @keyframes fadeOut {
                0% {
                    opacity: 1;
                    transform: translateY(startYposition);
                }
                100% {
                    opacity: 0;
                    transform: translateY(endYposition);
                }
            }
            
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            /* Firefox */
            
            input[type=number] {
                -moz-appearance: textfield;
            }
            
            .form-control {
                font-size: 16px;
                padding: 4px 4px;
                display: block;
                width: 100%;
                height: 48px;
                line-height: 50px;
                border-radius: 4px;
                font-family: 'Source Sans Pro', sans-serif;
                padding: 16px 16px 16px 16px;
                margin: 0px 0px 16px 0px;
                display: block;
                width: 100%;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #495057;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #757575;
                transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            }
            
            .select-container .form-control,
            .rbt-input-hint,
            .rbt-input-main {
                padding-right: 45px !important;
                text-overflow: ellipsis !important;
                overflow: hidden !important;
                white-space: nowrap !important;
                text-transform: capitalize;
            }
            .select-container .disabled{
                background-color: #e9ecef;
                opacity: .5;
            }
            
            .form-control:focus {
                outline: none;
            }
            
            .rbt .rbt-input-main::-ms-clear {
                display: none
            }
            
            .rbt-menu {
                margin-bottom: 2px
            }
            
            .rbt-menu.dropdown-menu {
                max-height: 242px !important;
            }
            
            .rbt-menu>.dropdown-item {
                overflow: hidden;
                text-overflow: ellipsis
            }
            
            .rbt-menu>.dropdown-item.disabled {
                padding: 16px 16px 16px 16px;
            }
            
            .rbt-menu>.dropdown-item:hover {
                background: #3c7ab5;
                color: #fff;
            }
            
            .rbt-menu a.active {
                background: #3c7ab5;
                color: #fff;
            }
            
            .rbt-menu>.dropdown-item:focus {
                background: #3c7ab5;
                color: #fff;
                outline: none
            }
            
            .rbt-menu-pagination-option {
                text-align: center
            }
            
            .rbt-input-multi {
                cursor: text;
                overflow: hidden;
                position: relative
            }
            
            .rbt-input-multi.focus {
                border-color: #80bdff;
                box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
                color: #495057;
                outline: 0
            }
            
            .blue-border-color {
                border-color: #3c7ab5;
            }
            
            .rbt-input-multi.form-control {
                height: auto !important;
                min-height: 47px !important;
                padding-bottom: 8px;
            }
            
            .rbt-input-multi.form-control[disabled],
            .rbt-input-main.form-control[disabled] {
                background-color: #e9ecef;
                opacity: 1
            }
            
            .rbt-input-multi.is-invalid.focus {
                border-color: #dc3545;
                box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
            }
            
            .rbt-input-multi.is-valid.focus {
                border-color: #28a745;
                box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25)
            }
            
            .rbt-input-multi input::-moz-placeholder {
                color: gray;
                opacity: 0.7;
            }
            
            .rbt-input-multi input:-ms-input-placeholder {
                color: gray;
                opacity: 0.7;
            }
            
            .rbt-input-multi input::-webkit-input-placeholder {
                color: gray;
                opacity: 0.7;
            }
            
            .rbt-input-multi .rbt-input-wrapper {
                align-items: flex-start;
                display: flex;
                flex-wrap: wrap;
                margin-bottom: -4px;
                margin-top: -5px;
                overflow: hidden
            }
            
            .rbt-input-multi .rbt-input-main {
                margin: -4px 0 4px !important
            }
            
            .rbt-close {
                z-index: 1
            }
            
            .rbt-close-lg {
                font-size: 24px
            }
            
            .rbt-token {
                background-color: #e7f4ff;
                border: 0;
                border-radius: .25rem;
                color: #3c7ab5;
                display: inline-block;
                line-height: 1em;
                margin: 1px 3px 2px 0;
                padding: 4px 7px;
                position: relative
            }
            
            .rbt-token-disabled {
                background-color: rgba(0, 0, 0, .1);
                color: #495057;
                pointer-events: none
            }
            
            .rbt-token-removeable {
                cursor: pointer;
                padding-right: 21px
            }
            
            .rbt-token-active {
                background-color: #3c7ab5;
                color: #fff;
                outline: none;
                text-decoration: none
            }
            
            .rbt-token .rbt-token-remove-button {
                bottom: 0;
                color: inherit;
                font-size: inherit;
                font-weight: normal;
                opacity: 1;
                outline: none;
                padding: 3px 7px;
                position: absolute;
                right: 0;
                text-shadow: none;
                top: -2px
            }
            
            .rbt-aux {
                align-items: center;
                display: flex;
                bottom: 0;
                justify-content: center;
                pointer-events: none;
                position: absolute;
                right: 0;
                top: 0;
                width: 34px
            }
            
            .rbt-aux-lg {
                width: 46px
            }
            
            .rbt-aux .rbt-close {
                margin-top: -4px;
                pointer-events: auto
            }
            
            .has-aux .rbt-input {
                padding-right: 34px
            }
            
            .rbt-highlight-text {
                background-color: inherit;
                color: inherit;
                font-weight: bold;
                padding: 0
            }
            
            .input-group>.rbt {
                flex: 1
            }
            
            .input-group>.rbt .rbt-input-hint,
            .input-group>.rbt .rbt-aux {
                z-index: 5
            }
            
            .input-group>.rbt:not(:first-child) .form-control {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0
            }
            
            .input-group>.rbt:not(:last-child) .form-control {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0
            }
            
            .rbt-aux .rbt-close {
                margin-top: -18px;
                margin-left: -40px;
                pointer-events: auto;
            }
            
            .rbt-aux .rbt-close span {
                font-size: 24px;
                display: inline-block;
                position: absolute;
                top: 10px;
                left: -8px;
            }
            
            input[type="checkbox"]:disabled {
                filter: invert(50%)
            }
            
            .credit-input>label:first-child {
                width: 80%;
            }
            
            .credit-card-input-wrapper {
                display: inline-block;
                position: relative;
                width: 100%;
            }
            
            .credit-card-input-wrapper input {
                margin-bottom: 0px !important;
            }
            
            .credit-card-input-wrapper svg {
                position: absolute;
                right: 10px;
                bottom: 30%;
                font-size: 1.5em;
            }
            
            .credit-card-input-wrapper small {
                color: #D8003b;
                font-size: 12px;
                display: block;
                position: absolute;
                bottom: -17px;
            }
            
            .focus-visible-none:focus-visible {
                outline: none !important;
            }
            
            select:focus,
            select:focus-within {
                outline: none !important;
                border: none !important
            }
            
            .listbox-button:focus {
                border: 2px solid blue !important
            }

            /* Hide the default date picker icon */
            input[type="date"]::-webkit-calendar-picker-indicator { 
                position: absolute;
                right: 5px;
                top: 50%;
                transform: translateY(-50%);
            }

            input[type="date"] {
                position: relative;
            }