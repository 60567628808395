@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
@import './shared/styles/colors.css';
@import './shared/styles/fonts.css';
@import './shared/styles/buttons.css';
@import './shared/styles/form.css';
@import './shared/styles/messages.css';
@import './shared/styles/phone.css';
body {
    /*background-color: #EDF4FD !important;*/
}

div.tabs-wrapper {
    border-bottom: 1px solid #ccc;
}

div.tabs-wrapper button:focus {
    outline: none !important;
}

.MuiDialog-paper {
    overflow: visible !important;
    margin-top: 0px !important;
}

.MuiDialog-scrollPaper {
    align-items: start !important;
    margin-top: 40px !important;
}

select.goog-te-combo:nth-child(2) {
    display: none;
}

@media print {
    .noprint {
        visibility: hidden;
        height: 0px !important;
        width: 0px !important;
        position: absolute;
    }
    .card-gray {
        background-color: beige;
        border: 1px solid black;
        border-radius: 0px !important;
        margin-top: 20px auto;
    }
}

.gt-sm\:grid-cols-7 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
}

.gt-sm\:grid-cols-6 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
}

.gt-sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
}

.gt-sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
}

.gt-sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}

.gt-sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.gt-sm\:top-14\.5 {
    top: 3.75rem !important;
}

.mapboxgl-ctrl-logo,
.mapboxgl-ctrl-bottom-right {
    display: none !important;
}

.notifications .container {
    border-radius: 10px;
    font-family: "'Source Sans Pro', sans-serif" !important
}

.notifications .container .header .header-title {
    font-size: 1rem;
}

.notifications .container .header .header-option {
    font-size: 1rem;
    color: #3c7ab5;
}

.notifications .card .image {
    display: none;
}

.notifications a {
    text-decoration: underline;
    color: #3c7ab5;
    font-weight: 600;
}

.notifications .card .text {
    font-size: .90rem;
}

.notifications .card {
    cursor: default;
}

.notifications .image {
    filter: invert(43%) sepia(49%) saturate(672%) hue-rotate(168deg) brightness(90%) contrast(85%);
}

.notifications .icon .count {
    background-color: #a62316 !important;
}

.react-tel-input .flag-dropdown {
    border: none !important;
    background-color: transparent !important;
    left: 5px;
}

.MuiPaper-rounded {
    border-radius: 20px !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
    background-color: transparent !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
    background-color: transparent !important;
}

/* ::-webkit-validation-bubble {
  display: none;
} */