* {
    font-family: 'Source Sans Pro', sans-serif !important;
}

.text-h1 {
    font-size: 40px;
    line-height: 48px;
    margin: 16px 16px 16px 0;
    font-weight: normal;
}

.text-h2 {
    font-size: 32px;
    line-height: 40px;
    margin: 16px 16px 16px 0;
    font-weight: normal;
}

.text-h3 {
    font-size: 24px;
    line-height: 32px;
    margin: 16px 16px 16px 0;
    font-weight: normal;
}

.text-h4 {
    font-size: 20px;
    line-height: 24px;
    margin: 16px 16px 16px 0;
    font-weight: normal;
}

.text-h5 {
    font-size: 18px;
    line-height: 22px;
    margin: 16px 16px 16px 0;
    font-weight: normal;
}

.text-p {
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
}

.text-mid-p {
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
}

.text-small {
    font-size: 12px;
    line-height: 18px;
    font-weight: normal;
}

a.text-link {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
}